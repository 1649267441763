export const FieldWithValue = ({fieldName, fieldValue}: any) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        textAlign: 'left',
      }}>
      <div style={{textTransform: 'capitalize'}}>{fieldName}:</div>
      <div style={{fontWeight: 'normal', color: '#4f3cc9'}}>{fieldValue}</div>
      <br />
    </div>
  )
}
