import {DateField, SimpleShowLayout, TextField} from 'react-admin'

export const AddressTab = ({record}: any) => {
  return (
    <SimpleShowLayout record={record}>
      <TextField source="address['id']" label="Address ID" />
      <TextField source="address['isoCountryCode']" label="ISO Country Code" />
      <TextField source="address['city']" label="City" />
      <TextField source="address['postalCode']" label="PostalCode" />
      <TextField source="address['street']" label="Street" />
      <TextField source="address['number']" label="Street #" />
      <TextField
        source="address['stateOrProvince']"
        label="State Or Province"
      />
      <TextField source="address['status']" label="Address Status" />
      <DateField source="address['createdAt']" showTime label="Created At" />
      <DateField source="address['updatedAt']" showTime label="Updated At" />
    </SimpleShowLayout>
  )
}
