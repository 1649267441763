import './index.css'

export const TimelineWidget = ({data}: any) => {
  return (
    <div className="timeline-container">
      {data.map((timeline: any) => (
        <div key={timeline.label} className="timeline-item">
          <div className="timeline-content">
            <p className="timeline-label">{timeline.label}</p>
            <p className="timeline-value">{timeline.value}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
