import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const CustomBarChart = ({consumers}: any) => {
  const data = [
    {
      name: 'Registration',
      Registration: consumers.total || 0,
    },
    {
      name: 'POA',
      Validated: consumers.poa?.valid || 0,
      Rejected: consumers.poa?.invalid || 0,
      'Not Validated': consumers.poa?.not_validated || 0,
    },
    {
      name: 'KYC',
      Validated: consumers.kyc?.validated || 0,
      Rejected: consumers.kyc?.invalid || 0,
      'In Progress': consumers.kyc?.in_progress || 0,
      'Not Fully Verified': consumers.kyc?.not_fully_verified || 0,
    },

    {
      name: 'Active users',
      Total: consumers.kyc?.validated,
    },
  ]

  return (
    <BarChart
      width={550}
      height={260}
      data={data}
      barSize={32}
      barGap={0}
      layout="vertical"
      margin={{
        left: -50,
      }}>
      <CartesianGrid
        strokeDasharray="3 3"
        stroke="#E7E9EB"
        horizontal={false}
      />
      <XAxis
        type="number"
        axisLine={false}
        orientation="top"
        tickLine={false}
        tick={<CustomizedAxisTick />}
      />
      <YAxis
        dataKey="name"
        tick={false}
        tickLine={false}
        axisLine={false}
        type="category"
      />
      <Tooltip
        cursor={{fill: 'transparent'}}
        contentStyle={{
          color: 'white',
          borderRadius: 8,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        }}
      />
      <Legend content={renderCustomLegend} />
      <Bar dataKey="Registration" stackId="a" fill="#1782CA">
        <LabelList
          position="center"
          dataKey="Registration"
          style={{
            fill: 'white',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>
      <Bar dataKey="Validated" stackId="a" fill="#DAF0DF">
        <LabelList
          position="center"
          dataKey="Validated"
          style={{
            fill: '#113453',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>

      <Bar dataKey="In Progress" stackId="a" fill="#FFEBCC">
        <LabelList
          position="center"
          dataKey="In Progress"
          style={{
            fill: '#113453',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>

      <Bar dataKey="Not Fully Verified" stackId="a" fill="#F3D0EB">
        <LabelList
          position="center"
          dataKey="Not Fully Verified"
          style={{
            fill: '#113453',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>

      <Bar dataKey="Rejected" stackId="a" fill="#F3D0D2">
        <LabelList
          position="center"
          dataKey="Rejected"
          style={{
            fill: '#113453',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>
      <Bar dataKey="Not Validated" stackId="a" fill="#E7E9EB">
        <LabelList
          position="center"
          dataKey="Not Validated"
          style={{
            fill: '#113453',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>

      <Bar dataKey="Total" stackId="a" fill="#44B461">
        <LabelList
          position="center"
          dataKey="Total"
          style={{
            fill: 'white',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '500',
          }}
        />
      </Bar>
    </BarChart>
  )
}

const CustomizedAxisTick = (props: any) => {
  const {x, y, payload} = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor="middle"
        fill="#A0A8AF"
        fontSize="12px"
        fontFamily="Inter">
        {payload.value}
      </text>
    </g>
  )
}

const renderCustomLegend = (props: any) => {
  const {payload} = props
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 12,
      }}>
      {payload.map((entry: any, index: number) => {
        if (['Registration', 'Total'].includes(entry.value)) return null

        return (
          <div
            key={`item-${index}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 12,
              marginBottom: 5,
            }}>
            <span
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '4px',
                backgroundColor: entry.color,
              }}></span>
            <span
              style={{
                fontSize: 12,
                color: '#586673',
                fontWeight: '400',
                fontFamily: 'Inter',
              }}>
              {entry.value}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default CustomBarChart
