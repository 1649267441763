import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import StackedBarChart from './chart'
import './index.css'
import {TimelineWidget} from './timeline'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
)

export const RegistrationWidget = ({consumers}: any) => {
  return (
    <div className="registration-wg-container">
      <p className="registration-wg-label">Registration funnel</p>
      <div className="registration-funnel">
        <TimelineWidget
          data={[
            {
              label: 'Registration',
              value: consumers.total,
            },
            {
              label: 'POA',
              value: consumers.poa?.total || 0,
            },
            {
              label: 'KYC',
              value: consumers.kyc?.total || 0,
            },
            {
              label: 'Active users',
              value: consumers.kyc?.validated,
            },
          ]}
        />

        <StackedBarChart {...{consumers}} />
      </div>
    </div>
  )
}
