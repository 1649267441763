import {Typography} from '@mui/material'
import {flag} from 'country-emoji'
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import React from 'react'
import {FieldProps, useRecordContext} from 'react-admin'

interface PhoneNumberProps extends FieldProps {
  source: string
  label?: string
}

export const PhoneNumberWithFlagField: React.FC<PhoneNumberProps> = props => {
  const record = useRecordContext(props)
  if (!record) return null

  const phoneNumber = record.phoneNumber
  const phoneCountryCode = record.phoneCountryCode

  const fullPhoneNumber = `${phoneCountryCode}${phoneNumber}`
  const phoneNumberObject = parsePhoneNumberFromString(fullPhoneNumber)

  if (!phoneNumberObject) return <Typography>Invalid phone number</Typography>

  const countryCode = phoneNumberObject.country
  const flagEmoji = flag(countryCode ?? '')
  const formattedNumber = phoneNumberObject.formatInternational()

  return (
    <Typography className="MuiTypography-root MuiTypography-body2">
      {flagEmoji} {formattedNumber}
    </Typography>
  )
}
