import {AuthProvider} from 'react-admin'

export const authProvider: AuthProvider = {
  login: async ({username, password}: any) => {
    const request = new Request('api/auth/login', {
      method: 'POST',
      body: JSON.stringify({email: username, password: password}),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((auth: any) => {
        if (!auth?.success) {
          return Promise.reject('Invalid username or password')
        }

        const token = `Bearer ${auth['token']}`
        localStorage.setItem('admin-profile', JSON.stringify(auth.message))
        localStorage.setItem('userId', auth.userId)
        localStorage.setItem('Authorization', token)
        return Promise.resolve('/#')
      })
      .catch(() => {
        throw new Error('Network error')
      })
  },
  logout: () => {
    localStorage.removeItem('Authorization')
    return Promise.resolve('/login')
  },
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('Authorization')

      //TODO: rewrite here
      // useLogout()
      return Promise.reject({redirect: '/login', message: false})
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    const token = localStorage.getItem('Authorization')
    return token ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    try {
      const {id, firstName, lastName} = JSON.parse(
        //@ts-ignore
        localStorage.getItem('admin-profile'),
      )
      return Promise.resolve({
        id,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
