import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'

// import {TextField} from 'react-admin'
// DataGridWithIndex
import {FieldWithValue} from '../../../components'

export const DevicesTab = ({record}: any) => {
  return (
    record?.devices && (
      <div>
        <div>
          {/* <DataGridWithIndex
            data={record?.devices || null}
            bulkActionButtons={false}>
            <TextField fontSize={12} source="device.model" maxWidth={80} />
            <TextField fontSize={12} source="device.osVersion" maxWidth={80} />
            <TextField fontSize={12} source="appVersion" maxWidth={80} />
            <TextField fontSize={12} source="status" maxWidth={80} />
            <TextField fontSize={12} source="createdAt" maxWidth={80} />
          </DataGridWithIndex> */}
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">OS</TableCell>
                <TableCell align="left">Model</TableCell>
                <TableCell align="left">OsVersion</TableCell>
                <TableCell align="left">App Version</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.devices.map((device: any) => (
                <DeviceRow key={device.id} row={device} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  )
}

const DeviceRow = (props: any) => {
  const {row} = props
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          '&:hover': {
            backgroundColor: '#E8E8E8',
            cursor: 'pointer', // Change this to the darker color you desire
          },
        }}
        onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {row.device.os}
        </TableCell>
        <TableCell align="left">{row.device.model}</TableCell>
        <TableCell align="left">{row.device.osVersion}</TableCell>
        <TableCell align="left">{row.appVersion}</TableCell>
        <TableCell align="left">{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{margin: 1}}
              style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div>
                  <FieldWithValue fieldName="Id" fieldValue={row.id} />
                  <FieldWithValue fieldName="Id" fieldValue={row.id} />
                  <FieldWithValue
                    fieldName="Device Id"
                    fieldValue={row.deviceId}
                  />
                </div>
                <div style={{margin: '10px'}}>
                  <FieldWithValue
                    fieldName="Created At"
                    fieldValue={row.createdAt}
                  />
                  <FieldWithValue
                    fieldName="Updated At"
                    fieldValue={row.updatedAt}
                  />
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
