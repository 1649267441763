import {Grid} from '@mui/material'
import {Box} from '@mui/system'
import {useState} from 'react'
import {EditButton, EmailField, TextField, useDataProvider} from 'react-admin'
import {DataGridWithIndex} from '../../components'
import {CustomList} from '../../components/layout/CustomList'
import {EditSVG} from '../../components/layout/icons/edit'
import {AdminSidePanel} from './adminShowPanel'

export const AdminList = ({...props}) => {
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const dataProvider = useDataProvider()
  const handleRowClick = async (id: any) => {
    const entityData = await dataProvider.getOne('admins', {id: id})

    setSelectedRecord(entityData.data)
    setIsOpen(true)
    return Promise.resolve('')
  }
  const togglePanel = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomList {...props} disablePagination customActions={['export']}>
          <DataGridWithIndex rowClick={handleRowClick}>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="role" />
            <EditButton
              className="grid-edit-button"
              icon={<EditSVG />}
              {...props}
            />
          </DataGridWithIndex>
        </CustomList>
      </Grid>
      <Grid item xs={6} md={4}>
        <Box display="flex" alignItems="flex-start" mt={8} height="100%">
          {selectedRecord && (
            <AdminSidePanel
              isOpen={isOpen}
              togglePanel={togglePanel}
              record={selectedRecord}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
