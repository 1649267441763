import {ExportButton, List, Pagination, TopToolbar} from 'react-admin'
import {ExcelSVG} from './icons/excel'

const ListActions = ({listOfActions}: any) => {
  return (
    <TopToolbar>
      {listOfActions.map((action: any) => {
        if (action === 'export')
          return (
            <ExportButton
              key="export"
              label="Export"
              icon={<ExcelSVG />}
              className="toolbar-export-button"
            />
          )

        return null
      })}
    </TopToolbar>
  )
}

export const CustomList = (props: any) => {
  const {children, customActions, disablePagination, ...rest} = props

  const oProps = {
    pagination: !disablePagination ? (
      <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
    ) : null,
    actions: customActions ? (
      <ListActions listOfActions={customActions} {...rest} />
    ) : undefined,
  }

  return (
    //@ts-ignore
    <List perPage={100} {...rest} {...oProps}>
      {children}
    </List>
  )
}
