export const TransactionsSVG = ({size = 24}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M11.3332 6.66701C11.3332 6.46082 11.2066 6.27577 11.0144 6.20102C10.8223 6.12628 10.6039 6.17715 10.4646 6.32915L6.79794 10.3291C6.66395 10.4753 6.629 10.6868 6.70884 10.8683C6.78868 11.0498 6.96823 11.167 7.16651 11.167H17.8332C18.1093 11.167 18.3332 10.9432 18.3332 10.667C18.3332 10.3909 18.1093 10.167 17.8332 10.167H11.3332V6.66701Z"
      fill="#1782CA"
    />
    <path
      d="M13.6665 17.3337L13.6665 13.8337H7.16651C6.89037 13.8337 6.66651 13.6098 6.66651 13.3337C6.66651 13.0575 6.89037 12.8337 7.16651 12.8337L17.8332 12.8337C18.0315 12.8337 18.211 12.9508 18.2909 13.1323C18.3707 13.3138 18.3357 13.5254 18.2018 13.6715L14.5351 17.6715C14.3958 17.8235 14.1774 17.8744 13.9853 17.7997C13.7931 17.7249 13.6665 17.5399 13.6665 17.3337Z"
      fill="#1782CA"
    />
  </svg>
)
