export const AccountsSVG = ({size = 24}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M18.5674 9.33569C18.5296 9.3333 18.4885 9.33332 18.4459 9.33334L18.4352 9.33334H16.7629C15.3845 9.33334 14.205 10.4184 14.205 11.8333C14.205 13.2483 15.3845 14.3333 16.7629 14.3333H18.4352L18.4459 14.3333C18.4885 14.3334 18.5296 14.3334 18.5674 14.331C19.1273 14.2956 19.6225 13.8575 19.6641 13.2455C19.6669 13.2053 19.6668 13.1621 19.6668 13.122L19.6668 13.1111V10.5556L19.6668 10.5447C19.6668 10.5046 19.6669 10.4613 19.6641 10.4212C19.6225 9.8092 19.1273 9.37105 18.5674 9.33569ZM16.6146 12.5C16.9695 12.5 17.2572 12.2015 17.2572 11.8333C17.2572 11.4651 16.9695 11.1667 16.6146 11.1667C16.2597 11.1667 15.972 11.4651 15.972 11.8333C15.972 12.2015 16.2597 12.5 16.6146 12.5Z"
      fill="#1782CA"
    />
    <path
      d="M18.4454 15.3333C18.5448 15.3308 18.6201 15.4228 18.5931 15.5185C18.4593 15.9935 18.2469 16.3983 17.906 16.7392C17.4071 17.2381 16.7745 17.4595 15.9928 17.5646C15.2334 17.6667 14.2629 17.6667 13.0378 17.6667H11.6292C10.404 17.6667 9.43362 17.6667 8.67415 17.5646C7.89253 17.4595 7.2599 17.2381 6.76099 16.7392C6.26208 16.2403 6.04067 15.6076 5.93559 14.826C5.83348 14.0665 5.83349 13.0961 5.8335 11.8709V11.7957C5.83349 10.5706 5.83348 9.60013 5.93559 8.84065C6.04067 8.05904 6.26208 7.4264 6.76099 6.9275C7.2599 6.42859 7.89253 6.20718 8.67415 6.10209C9.43362 5.99998 10.404 5.99999 11.6292 6L13.0378 6C14.2629 5.99999 15.2334 5.99998 15.9928 6.10209C16.7745 6.20718 17.4071 6.42859 17.906 6.9275C18.2469 7.26835 18.4593 7.67316 18.5931 8.14812C18.6201 8.24384 18.5448 8.33591 18.4454 8.33334L16.7628 8.33334C14.8717 8.33334 13.2049 9.82727 13.2049 11.8333C13.2049 13.8394 14.8717 15.3333 16.7628 15.3333L18.4454 15.3333ZM8.3335 8.66667C8.05735 8.66667 7.8335 8.89052 7.8335 9.16667C7.8335 9.44281 8.05735 9.66667 8.3335 9.66667H11.0002C11.2763 9.66667 11.5002 9.44281 11.5002 9.16667C11.5002 8.89052 11.2763 8.66667 11.0002 8.66667H8.3335Z"
      fill="#1782CA"
    />
  </svg>
)
