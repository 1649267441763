export const AdminsSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M12.8807 5.39987L17.3056 6.88451C17.8009 7.0499 18.1358 7.5049 18.1385 8.01482L18.1667 12.4419C18.1754 13.784 17.6862 15.0856 16.7902 16.1055C16.3781 16.5735 15.8499 16.9756 15.1754 17.3351L12.7968 18.6067C12.7223 18.6459 12.6404 18.6661 12.5578 18.6668C12.4753 18.6675 12.3927 18.6478 12.3189 18.6093L9.91817 17.3672C9.23628 17.0135 8.70338 16.6173 8.28726 16.1558C7.3765 15.1464 6.87044 13.8507 6.86172 12.5066L6.83351 8.08281C6.83084 7.57224 7.15971 7.11397 7.65234 6.94204L12.0605 5.40445C12.3223 5.31162 12.6142 5.30966 12.8807 5.39987ZM14.9967 10.1459C14.7987 9.9557 14.4799 9.957 14.2846 10.1499L12.0389 12.3634L11.1194 11.4796C10.9214 11.2893 10.6033 11.2913 10.4073 11.4841C10.212 11.677 10.214 11.9869 10.412 12.1771L11.6892 13.4061C11.7886 13.5016 11.9174 13.5486 12.0463 13.5473C12.1751 13.5467 12.3033 13.4983 12.4013 13.4015L15.0007 10.8389C15.196 10.646 15.194 10.3362 14.9967 10.1459Z"
      fill="#1782CA"
    />
  </svg>
)
