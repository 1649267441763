import {Grid} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import {
  DateField,
  EmailField,
  Filter,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
} from 'react-admin'
import {useNavigate, useParams} from 'react-router-dom'
import {DataGridWithIndex, PhoneNumberWithFlagField} from '../../components'
import {CommonSidePanel} from '../../components/layout/CommonSidePanel'
import {CustomList} from '../../components/layout/CustomList'
import {EEACountries} from '../../helpers/eu-countries'
import AddressStatusField from './fields/address-field'
import KYCStatusField from './fields/kyc-status-field'
import LocationField from './fields/location-field'
import UserStatusField from './fields/user-status-field'
import {
  AccountsTab,
  AddressTab,
  CardsTab,
  InfoTab,
  KYCTab,
  TransactionsTab,
} from './tabs'
import {DevicesTab} from './tabs/devices'

const countries = [{code: 'SK', name: 'Slovakia'}]

const fetchUniqCountriesData = async () => {
  const myHeaders = new Headers()
  myHeaders.append('Authorization', localStorage.getItem('Authorization') ?? '')

  const response = fetch(`api/consumers/uniq-countries`, {
    method: 'GET',
    headers: myHeaders,
  }).then(response => response.json())

  return response
}

const UserFilter = (props: any) => {
  const [countries, setCountries] = useState<any>()

  useEffect(() => {
    fetchUniqCountriesData().then((data: any) => {
      setCountries(data)
    })
  }, [])

  return (
    <Filter {...props}>
      <TextInput label="Id" source="id" />
      <TextInput label="LinkcyId" source="linkcyId" />
      <TextInput label="Email" source="email" />
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="Status" source="status" />
      <SelectInput
        label="KYC Status"
        source="kycStatus"
        choices={[
          {id: 'not_started', name: 'Not Started'},
          {id: 'in_progress', name: 'In Progress'},
          {id: 'pending', name: 'Pending'},
          {id: 'validated', name: 'Validated'},
          {id: 'not_fully_verified', name: 'Not Fully Verified'},
          {id: 'invalid', name: 'Invalid'},
        ]}
      />
      <SelectInput
        label="Address"
        source="address.isoCountryCode"
        choices={EEACountries.map(country => ({
          id: country.iso3,
          name: country.name,
        }))}
      />
      <SelectInput
        label="IP Country"
        source="registrationIpDetails.countryCode"
        choices={
          countries?.map((country: any) => ({
            id: country.code,
            name: country.name,
          })) || []
        }
      />
      <TextInput label="Phone Number" source="phoneNumber" />
    </Filter>
  )
}

const fetchConsumerData = async (id: string, dataProvider: any) => {
  const entity: any = await dataProvider.getOne('consumers', {id: id})

  const linkcyId = entity['data']['linkcyId']
  const myHeaders = new Headers()
  myHeaders.append('Authorization', localStorage.getItem('Authorization') ?? '')

  if (linkcyId) {
    const [consumerCards, consumerTransactions] = await Promise.all([
      fetch(`api/consumers/${linkcyId}/cards`, {
        method: 'GET',
        headers: myHeaders,
      }).then(response => response.json()),
      fetch(`api/consumers/${linkcyId}/transactions`, {
        method: 'GET',
        headers: myHeaders,
      }).then(response => response.json()),
    ])
    entity['data']['transactions'] = consumerTransactions['content']
    entity['data']['cards'] = consumerCards
  }
  return entity
}

export const ConsumersList = ({...props}) => {
  const params = useParams()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState<any>(null)

  useEffect(() => {
    const id = params['*']

    if (id) {
      fetchConsumerData(id, dataProvider).then(entity => {
        setRecord(entity)
      })
    }
  }, [params['*'], dataProvider])

  const handleRowClick = async (consumerId: any) => {
    return `/consumers/${consumerId}`
  }

  const sidePanelTabsList = [
    {name: 'General', TabComponent: InfoTab},
    {name: 'Address', TabComponent: AddressTab},
    {name: 'Accounts', TabComponent: AccountsTab},
    {name: 'Cards', TabComponent: CardsTab},
    {name: 'Transactions', TabComponent: TransactionsTab},
    {name: 'KYC', TabComponent: KYCTab},
    {name: 'Devices', TabComponent: DevicesTab},
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomList
          {...props}
          // customActions={['export']}
          filters={<UserFilter />}
          sort={{field: 'createdAt', order: 'DESC'}}>
          <DataGridWithIndex
            {...props}
            bulkActionButtons={false}
            style={{zIndex: 0}}
            rowClick={handleRowClick}>
            <TextField fontSize={12} source="firstName" maxWidth={60} />
            <TextField
              fontSize={12}
              source="lastName"
              maxWidth={60}
              display="inline-flex"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            />
            <EmailField fontSize={12} source="email" />
            <UserStatusField source="status" />
            <KYCStatusField source="kycStatus" label="KYC" />
            <AddressStatusField source="address.status" label="Address" />
            <LocationField source="registrationIpDetails" label="IP Location" />

            {/* <PhoneNumberWithFlag source="phoneNumber" /> */}
            <PhoneNumberWithFlagField
              source="phoneNumber"
              label="Phone Number"
            />
            <DateField fontSize={12} source="createdAt" />
          </DataGridWithIndex>
        </CustomList>
      </Grid>
      <Grid item xs={4} md={3}>
        <Box display="flex" alignItems="flex-start" mt={8} height="100%">
          <CommonSidePanel
            title="Consumer Profile"
            record={record}
            list={sidePanelTabsList}
            togglePanel={() => {
              setRecord(undefined)
              navigate('/consumers', {replace: true})
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
