export const ProfileSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5333 15.1605C16.5333 17.9072 12.7666 18.2247 9.93409 18.2247L9.73139 18.2245C7.92676 18.2201 3.33325 18.1062 3.33325 15.1438C3.33325 12.4532 6.94855 12.0937 9.75949 12.0801L10.1368 12.0799C11.9413 12.0842 16.5333 12.1982 16.5333 15.1605ZM9.93409 13.3297C6.38325 13.3297 4.58325 13.9397 4.58325 15.1438C4.58325 16.3588 6.38325 16.9747 9.93409 16.9747C13.4841 16.9747 15.2833 16.3647 15.2833 15.1605C15.2833 13.9455 13.4841 13.3297 9.93409 13.3297ZM9.93409 1.66602C12.3741 1.66602 14.3583 3.65102 14.3583 6.09102C14.3583 8.53102 12.3741 10.5152 9.93409 10.5152H9.90742C7.47242 10.5077 5.49992 8.52185 5.50823 6.08852C5.50823 3.65102 7.49325 1.66602 9.93409 1.66602ZM9.93409 2.85602C8.14992 2.85602 6.69823 4.30685 6.69823 6.09102C6.69242 7.86935 8.13325 9.31935 9.90992 9.32602L9.93409 9.92102V9.32602C11.7174 9.32602 13.1683 7.87435 13.1683 6.09102C13.1683 4.30685 11.7174 2.85602 9.93409 2.85602Z"
      fill="#586673"
    />
  </svg>
)
