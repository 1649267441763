export const ExcelSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9881 2.75359H11.6569V1.26172L1.25 2.86797V16.9473L11.6569 18.7398V16.5286H17.9881C18.1799 16.5383 18.3677 16.4717 18.5104 16.3433C18.6532 16.215 18.7393 16.0353 18.75 15.8436V3.43797C18.7392 3.24638 18.653 3.06686 18.5102 2.93863C18.3675 2.8104 18.1798 2.74388 17.9881 2.75359ZM18.0881 15.9573H11.6356L11.625 14.7767H13.1794V13.4017H11.6131L11.6056 12.5892H13.1794V11.2142H11.5938L11.5863 10.4017H13.1794V9.02672H11.5813V8.21422H13.1794V6.83922H11.5813V6.02672H13.1794V4.65172H11.5813V3.40172H18.0881V15.9573Z"
      fill="#20744A"
    />
    <path
      d="M14.0537 4.64941H16.7556V6.02441H14.0537V4.64941Z"
      fill="#20744A"
    />
    <path
      d="M14.0537 6.83789H16.7556V8.21289H14.0537V6.83789Z"
      fill="#20744A"
    />
    <path
      d="M14.0537 9.02539H16.7556V10.4004H14.0537V9.02539Z"
      fill="#20744A"
    />
    <path
      d="M14.0537 11.2139H16.7556V12.5889H14.0537V11.2139Z"
      fill="#20744A"
    />
    <path
      d="M14.0537 13.4023H16.7556V14.7773H14.0537V13.4023Z"
      fill="#20744A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96803 6.67027L5.30928 6.5934L6.1524 8.91152L7.14865 6.49777L8.4899 6.4209L6.86115 9.71215L8.4899 13.0115L7.07178 12.9159L6.11428 10.4009L5.15615 12.8203L3.85303 12.7053L5.36678 9.7909L3.96803 6.67027Z"
      fill="white"
    />
  </svg>
)
