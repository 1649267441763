import {Tooltip, Typography} from '@mui/material'
import {FC, ReactNode} from 'react'
import {useRecordContext} from 'react-admin'

interface LocationFieldProps {
  source: string
  label?: ReactNode
  width?: number
}

const LocationField: FC<LocationFieldProps> = ({source, width = 125}) => {
  const record = useRecordContext()
  const country = record[source]?.countryName || ''
  const city = record[source]?.city || ''

  let displayValue = ''
  if (city && country) {
    displayValue = `${city}, ${country}`
  } else if (city) {
    displayValue = city
  } else if (country) {
    displayValue = country
  }

  return (
    <Tooltip title={displayValue}>
      <Typography
        sx={{
          maxWidth: width,
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}>
        {displayValue}
      </Typography>
    </Tooltip>
  )
}

export default LocationField
