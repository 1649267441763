import {Typography} from '@mui/material'
import {FC, ReactNode, useMemo} from 'react'
import {useRecordContext} from 'react-admin'
type TransactionDirection = 'Received' | 'Sent'

const statusDisplayNames: Record<TransactionDirection, string> = {
  Received: 'Received',
  Sent: 'Sent',
}

const statusColors: Record<TransactionDirection, string> = {
  Received: '#4caf50', // Green
  Sent: '#f44336', // Red
}

const statusBackground: Record<TransactionDirection, string> = {
  Received: '#e8f5e9', // Light Green
  Sent: '#ffebee', // Light Red
}

interface TransactionDirectionFieldProps {
  source: string
  label?: ReactNode // This can be a string, element, etc.
}

const TransactionAmountField: FC<TransactionDirectionFieldProps> = ({
  source,
}) => {
  const record = useRecordContext()
  console.log(record)
  const trnDirection = parseInt(record[source]) > 0 ? 'Received' : 'Sent'
  const status = trnDirection as TransactionDirection

  const color = useMemo(() => statusColors[status] || '#000', [status])
  const backgroundColor = useMemo(
    () => statusBackground[status] || '#fff',
    [status],
  )
  const displayName = useMemo(
    () => statusDisplayNames[status] || status,
    [status],
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center', // Vertically centers the text
        justifyContent: 'center', // Horizontally centers the text
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        padding: '5px',
      }}>
      <Typography fontSize={10} style={{color: color}}>
        {record[source]}
      </Typography>
    </div>
  )
}

export default TransactionAmountField
