import {Layout as RALayout} from 'react-admin'
import {useLocation} from 'react-router'
import {AppBar} from './app-bar'

export const Layout = (props: any) => {
  const location = useLocation()

  return (
    <RALayout
      {...props}
      className={location.pathname !== '/' && 'app-pages'}
      sidebar={() => null}
      appBar={AppBar}
    />
  )
}
