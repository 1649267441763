// SidePanel.js

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {FieldWithValue} from '../../../components'

export const TransactionsTab = ({record}: any) => {
  return (
    record?.transactions && (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Sender</TableCell>
              <TableCell align="right">Receiver</TableCell>
              <TableCell align="right">Clearing Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record?.transactions.map((transaction: any) => (
              <TransactionRow key={transaction.name} row={transaction} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  )
}

const TransactionRow = (props: any) => {
  const {row} = props
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          '&:hover': {
            backgroundColor: '#E8E8E8',
            cursor: 'pointer', // Change this to the darker color you desire
          },
        }}
        onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {row.type}
        </TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">
          {row.amount} {row.currency}
        </TableCell>
        <TableCell align="right">{row.sender.name}</TableCell>
        <TableCell align="right">{row.receiver.name}</TableCell>
        <TableCell align="right">{row.creationDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{margin: 1}}
              style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div style={{margin: '10px'}}>
                  <FieldWithValue fieldName="Id" fieldValue={row.id} />
                  <FieldWithValue
                    fieldName="Payment Type"
                    fieldValue={row.paymentType}
                  />
                  <FieldWithValue
                    fieldName="Conversion Rate"
                    fieldValue={row.information.conversionRate}
                  />
                  <FieldWithValue
                    fieldName="Reference"
                    fieldValue={row.information.reference}
                  />
                  <FieldWithValue
                    fieldName="Clearing Date"
                    fieldValue={row.information.clearingDate}
                  />
                </div>
                <div style={{margin: '10px'}}>
                  <FieldWithValue
                    fieldName="Sender Id"
                    fieldValue={row.sender.endUserId}
                  />
                  <FieldWithValue
                    fieldName="Sender Ledger Id"
                    fieldValue={row.sender.ledgerId}
                  />
                  <FieldWithValue
                    fieldName="Receiver Id"
                    fieldValue={row.receiver.endUserId}
                  />
                  <FieldWithValue
                    fieldName="Receiver Ledger Id"
                    fieldValue={row.receiver.ledgerId}
                  />
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
