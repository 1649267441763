// SidePanel.js

import CloseIcon from '@mui/icons-material/Close'
import {Button, Typography, styled} from '@mui/material'
import React from 'react'
import {
  DateField,
  EditButton,
  EmailField,
  SimpleShowLayout,
  TextField,
} from 'react-admin'

interface SidePanelProps {
  isOpen: boolean
  togglePanel: () => void
  record: any
}

export const AdminSidePanel: React.FC<SidePanelProps> = ({
  isOpen,
  togglePanel,
  record,
}: any) => {
  const BoldValue = styled('span')(() => ({
    color: '#4f3cc9',
    margin: 5,
  }))

  return (
    <div>
      <div className={`side-panel ${isOpen ? 'open' : ''}`}>
        <div className="side-panel-container">
          <Button className="closebtn" onClick={togglePanel}>
            <CloseIcon />
          </Button>
          {/* Display the details of the record here */}
          <Typography variant="h5" textAlign={'center'}>
            Admin Information
          </Typography>
          <SimpleShowLayout record={record}>
            <TextField source="id" label="Id" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="role" />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
            <EditButton />
          </SimpleShowLayout>
        </div>
      </div>
      <div
        className={`overlay ${isOpen ? 'display' : ''}`}
        onClick={togglePanel}></div>
    </div>
  )
}
