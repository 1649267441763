import {Grid} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import {
  DateField,
  Filter,
  FunctionField,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
} from 'react-admin'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {DataGridWithIndex} from '../../components'
import {CommonSidePanel} from '../../components/layout/CommonSidePanel'
import {CustomList} from '../../components/layout/CustomList'
import {CardsTab, InfoTab, TransactionsTab} from './tabs'

//"PENDING" "ACTIVE" "CLOSING" "CLOSED" "SUSPENDED"
const AccountFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="IBAN" source="iban" />
    <TextInput label="BIC" source="bic" />
    <SelectInput
      label="Type"
      source="type"
      choices={[
        {id: 'personal', name: 'Personal'},
        {id: 'entrepreneur', name: 'FOP'},
      ]}
    />
    <TextInput label="Provider" source="provider" />
    <SelectInput
      label="Status"
      source="status"
      choices={[
        {id: 'PENDING', name: 'Pending'},
        {id: 'ACTIVE', name: 'Active'},
        {id: 'CLOSING', name: 'Closing'},
        {id: 'CLOSED', name: 'Closed'},
        {id: 'SUSPENDED', name: 'Suspended'},
      ]}
    />
  </Filter>
)

const fetchAccountData = async (id: string, dataProvider: any) => {
  const entity: any = await dataProvider.getOne('accounts', {id: id})

  //setRecord(entity)
  const linkcyId = entity['data']['providerId']
  const myHeaders = new Headers()
  myHeaders.append('Authorization', localStorage.getItem('Authorization') ?? '')

  if (linkcyId) {
    const accountCards = await fetch(`api/accounts/${linkcyId}/cards`, {
      method: 'GET',
      headers: myHeaders,
    }).then(response => response.json())
    const accountTransactions = await fetch(
      `api/accounts/${linkcyId}/transactions`,
      {
        method: 'GET',
        headers: myHeaders,
      },
    ).then(response => response.json())
    entity['data']['transactions'] = accountTransactions['content']
    entity['data']['cards'] = accountCards
  }
  return entity
}

export const AccountsList = ({...props}) => {
  const params = useParams()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState<any>(null)

  useEffect(() => {
    const id = params['*']

    if (id) {
      fetchAccountData(id, dataProvider).then(entity => {
        setRecord(entity)
      })
    }
  }, [params['*'], dataProvider])

  const sidePanelTabsList = [
    {name: 'General Info', TabComponent: InfoTab},
    {name: 'Cards', TabComponent: CardsTab},
    {name: 'Transactions', TabComponent: TransactionsTab},
  ]

  const handleRowClick = async (accountId: any) => {
    return Promise.resolve(`/accounts/${accountId}`)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomList
          {...props}
          // customActions={['export']}
          filters={<AccountFilter />}
          empty={false}
          sort={{field: 'createdAt', order: 'DESC'}}>
          <DataGridWithIndex {...props} rowClick={handleRowClick}>
            <FunctionField
              label="Consumer"
              style={{color: '#4f3cc9'}}
              render={(record: any) => (
                <Link
                  to={`/consumers/${record.user.id}`}
                  onClick={e => e.stopPropagation()}>
                  {`${record.user.lastName} ${record.user.firstName}`}
                </Link>
              )}
            />
            <TextField source="iban" textAlign="left" />
            <TextField source="bic" textAlign="left" />
            <NumberField source="type" textAlign="left" />
            <NumberField source="balance" textAlign="right" />
            {/*<MoneyCurrencyField {...props} />*/}
            <TextField source="provider" textAlign="left" />
            <TextField source="status" textAlign="left" />
            <TextField source="currency" textAlign="left" />
            <DateField source="createdAt" textAlign="left" showTime />
            <DateField source="updatedAt" textAlign="left" showTime />
          </DataGridWithIndex>
        </CustomList>
      </Grid>
      <Grid item xs={4} md={3}>
        <Box display="flex" alignItems="flex-start" mt={8} height="100%">
          <CommonSidePanel
            title="Account"
            record={record}
            list={sidePanelTabsList}
            togglePanel={() => {
              setRecord(undefined)
              navigate('/accounts', {replace: true})
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
