// SidePanel.js

import {ButtonGroup, Typography} from '@mui/material'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {CloseSVG} from './icons'

export const CommonSidePanel = ({record, title, list, togglePanel}: any) => {
  return (
    <div>
      <div className={`side-panel ${!!record ? 'open' : ''}`}>
        <div className="side-panel-container">
          <div className="side-panel-close-btn" onClick={togglePanel}>
            <CloseSVG />
          </div>
          <Typography className="side-panel-title" variant="h5">
            {title}
          </Typography>
          <Tabs className="side-panel-tabs">
            <TabList className="side-panel-tabs">
              <ButtonGroup
                variant="text"
                aria-label="outlined primary button group">
                {list.map((tab: any) => {
                  return (
                    <Tab key={tab.name} style={{margin: '5px'}}>
                      <div className="side-panel-tab-button">{tab.name}</div>
                    </Tab>
                  )
                })}
              </ButtonGroup>
            </TabList>
            {list.map((tab: any) => {
              return (
                <TabPanel key={tab.name}>
                  {record && <tab.TabComponent record={record['data']} />}
                </TabPanel>
              )
            })}
          </Tabs>
        </div>
      </div>
      <div
        className={`overlay ${!!record ? 'display' : ''}`}
        onClick={togglePanel}></div>
    </div>
  )
}
