import {useRecordContext} from 'react-admin'

export const CurrentIndexField = (props: any) => {
  const record = useRecordContext(props)

  return record && record.__index__ !== undefined ? (
    <div
      style={{
        maxWidth: '45px',
        textAlign: 'center',
        // display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
      {record.__index__}
    </div>
  ) : (
    <span></span>
  )
}
