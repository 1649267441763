export const AccountSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.533 15.1609C16.533 17.9075 12.7663 18.225 9.93384 18.225L9.73114 18.2249C7.92651 18.2205 3.33301 18.1066 3.33301 15.1442C3.33301 12.4536 6.9483 12.094 9.75924 12.0805L10.1365 12.0802C11.941 12.0846 16.533 12.1985 16.533 15.1609ZM9.93384 13.33C6.38301 13.33 4.58301 13.94 4.58301 15.1442C4.58301 16.3592 6.38301 16.975 9.93384 16.975C13.4838 16.975 15.283 16.365 15.283 15.1609C15.283 13.9459 13.4838 13.33 9.93384 13.33ZM9.93384 1.66638C12.3738 1.66638 14.358 3.65138 14.358 6.09138C14.358 8.53138 12.3738 10.5155 9.93384 10.5155H9.90717C7.47217 10.508 5.49967 8.52222 5.50798 6.08888C5.50798 3.65138 7.49301 1.66638 9.93384 1.66638ZM9.93384 2.85638C8.14967 2.85638 6.69799 4.30721 6.69799 6.09138C6.69217 7.86972 8.13301 9.31972 9.90968 9.32638L9.93384 9.92138V9.32638C11.7172 9.32638 13.168 7.87471 13.168 6.09138C13.168 4.30721 11.7172 2.85638 9.93384 2.85638Z"
      fill="#A0A8AF"
    />
  </svg>
)
