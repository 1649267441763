export const PasswordSVG = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.70817 13.3333C7.70817 12.0676 8.73419 11.0416 9.99984 11.0416C11.2655 11.0416 12.2915 12.0676 12.2915 13.3333C12.2915 14.5989 11.2655 15.625 9.99984 15.625C8.73419 15.625 7.70817 14.5989 7.70817 13.3333ZM9.99984 12.2916C9.42454 12.2916 8.95817 12.758 8.95817 13.3333C8.95817 13.9086 9.42454 14.375 9.99984 14.375C10.5751 14.375 11.0415 13.9086 11.0415 13.3333C11.0415 12.758 10.5751 12.2916 9.99984 12.2916Z"
      fill="#A0A8AF"
    />
    <path
      d="M4.37484 7.75227V6.66663C4.37484 3.56002 6.89324 1.04163 9.99984 1.04163C13.1064 1.04163 15.6248 3.56002 15.6248 6.66663V7.75227C15.8139 7.76556 15.992 7.78286 16.1596 7.8054C16.9097 7.90624 17.5413 8.12199 18.0429 8.62358C18.5445 9.12518 18.7602 9.75674 18.8611 10.5068C18.9582 11.2293 18.9582 12.1479 18.9582 13.2876V13.379C18.9582 14.5187 18.9582 15.4373 18.8611 16.1598C18.7602 16.9098 18.5445 17.5414 18.0429 18.043C17.5413 18.5446 16.9097 18.7603 16.1596 18.8612C15.4372 18.9583 14.5186 18.9583 13.3789 18.9583H6.62078C5.48112 18.9583 4.56252 18.9583 3.84005 18.8612C3.08996 18.7603 2.45839 18.5446 1.9568 18.043C1.4552 17.5414 1.23945 16.9098 1.13861 16.1598C1.04147 15.4373 1.04149 14.5187 1.0415 13.379V13.2876C1.04149 12.1479 1.04147 11.2293 1.13861 10.5068C1.23945 9.75674 1.4552 9.12518 1.9568 8.62358C2.45839 8.12199 3.08996 7.90624 3.84005 7.8054C4.00765 7.78286 4.18581 7.76556 4.37484 7.75227ZM5.62484 6.66663C5.62484 4.25038 7.58359 2.29163 9.99984 2.29163C12.4161 2.29163 14.3748 4.25038 14.3748 6.66663V7.71116C14.0636 7.70828 13.7318 7.70829 13.3789 7.70829H6.62078C6.26784 7.70829 5.93611 7.70828 5.62484 7.71116V6.66663ZM4.00661 9.04425C3.39513 9.12646 3.07132 9.27683 2.84068 9.50747C2.61004 9.7381 2.45967 10.0619 2.37746 10.6734C2.29283 11.3029 2.29151 12.1371 2.29151 13.3333C2.29151 14.5295 2.29283 15.3637 2.37746 15.9932C2.45967 16.6047 2.61004 16.9285 2.84068 17.1591C3.07132 17.3898 3.39513 17.5401 4.00661 17.6223C4.63606 17.707 5.47032 17.7083 6.6665 17.7083H13.3332C14.5294 17.7083 15.3636 17.707 15.9931 17.6223C16.6046 17.5401 16.9284 17.3898 17.159 17.1591C17.3896 16.9285 17.54 16.6047 17.6222 15.9932C17.7068 15.3637 17.7082 14.5295 17.7082 13.3333C17.7082 12.1371 17.7068 11.3029 17.6222 10.6734C17.54 10.0619 17.3896 9.7381 17.159 9.50747C16.9284 9.27683 16.6046 9.12646 15.9931 9.04425C15.3636 8.95962 14.5294 8.95829 13.3332 8.95829H6.6665C5.47032 8.95829 4.63606 8.95962 4.00661 9.04425Z"
      fill="#A0A8AF"
    />
  </svg>
)
