import {utils, writeFile} from 'xlsx'

export const exporter = (filename: string) => (dataList: any) => {
  const flattenObject = (ob: any) => {
    const toReturn: any = {}
    for (const i in ob) {
      if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
        const temp = flattenObject(ob[i])
        for (const j in temp) {
          toReturn[i + '.' + j] = temp[j]
        }
      } else {
        toReturn[i] = ob[i]
      }
    }
    return toReturn
  }

  const simpleExporter = (collectedData: any) => {
    const flattenedData = collectedData.map(flattenObject)
    const date = new Date()
    let workbook = utils.book_new()
    utils.book_append_sheet(
      workbook,
      utils.json_to_sheet(flattenedData),
      `${filename}`,
    )
    writeFile(workbook, `${filename}_${date}.xlsx`)
  }

  simpleExporter(dataList)
}
