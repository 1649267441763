import {DateField, SimpleShowLayout, TextField, NumberField} from 'react-admin'
import {FieldWithValue} from '../../../components'
import React from 'react'

export const InfoTab = ({record}: any) => {
  return (
    <SimpleShowLayout record={record}>
      <TextField source="id" label="Id" />
      <TextField source="type" label="Type" />
      <TextField source="paymentType" label="Payment Type" />
      <TextField source="status" label="Status" />
      {record.information.declineReason && (
        <TextField
          source='information["declineReason"]'
          label="Decline Reason"
        />
      )}
      {record.information.reference && (
        <TextField source='information["reference"]' label="Reference" />
      )}
      <DateField source="creationDate" showTime />
      <NumberField source="amount" label="Amount" />
      <TextField source="currency" label="Currency" />
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{margin: '10px'}}>
          <h5>Sender</h5>
          <FieldWithValue fieldName="Id" fieldValue={record.sender.endUserId} />
          <FieldWithValue fieldName="Name" fieldValue={record.sender.name} />
          <FieldWithValue
            fieldName="LedgerId"
            fieldValue={record.sender.ledgerId}
          />
        </div>
        <div style={{margin: '10px'}}>
          <h5>Receiver</h5>
          <FieldWithValue
            fieldName="Id"
            fieldValue={record.receiver.endUserId}
          />
          <FieldWithValue fieldName="Name" fieldValue={record.receiver.name} />
          <FieldWithValue
            fieldName="LedgerId"
            fieldValue={record.receiver.ledgerId}
          />
        </div>
      </div>
      <div>Conversion:</div>
      <DateField
        source='information["clearingDate"]'
        label="Clearing Date"
        showTime
      />
      <NumberField
        source='information["originalAmount"]'
        label="Original Amount"
      />
      <TextField
        source='information["originalCurrency"]'
        label="Original Currency"
      />
      <NumberField
        source='information["conversionRate"]'
        label="Conversion Rate"
      />
    </SimpleShowLayout>
  )
}
