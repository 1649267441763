// import {LinearProgress} from '@mui/material'
import {TransactionsChart} from './chart'
import './index.css'
import {TransactionType} from './transaction-type'

const COLORS = [
  '#1782CA',
  '#5CA7DA',
  '#B9D9EF',
  '#B9D9EF',
  '#B9D9EF',
  '#E8F2FA',
]

export const TransactionsWidget = ({transactions}: any) => {
  return (
    <div className="transactions-wg-container">
      <p className="transactions-wg-label">Number of transactions</p>
      <p className="transactions-total">{transactions.total || 0}</p>

      <TransactionsChart {...{transactions}} />

      <p className="transactions-type-label">Transaction type distribution</p>

      {(transactions.details || []).map((tr: any, index: number) => (
        <TransactionType
          type={tr.paymentType}
          percent={tr.percentage}
          color={COLORS[index]}
        />
      ))}

      {/* <TransactionType />
      <TransactionType />
      <TransactionType /> */}
    </div>
  )
}
