import LocalPoliceIcon from '@mui/icons-material/LocalPolice'; //Look for new icon

import {AdminCreate} from './adminCreate';
import {AdminEdit} from './adminEdit';
import {AdminList} from './adminList';

export default {
  list: AdminList,
  edit: AdminEdit,
  icon: LocalPoliceIcon,
  create: AdminCreate,
};
