import {FC, useMemo} from 'react'
import {useRecordContext} from 'react-admin'

import {Typography} from '@mui/material'

export enum UserStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  DEACTIVATED = 'deactivated',
  PENDING = 'pending',
}

const userStatusDisplayNames: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: 'Active',
  [UserStatus.SUSPENDED]: 'Suspended',
  [UserStatus.DEACTIVATED]: 'Deactivated',
  [UserStatus.PENDING]: 'Pending',
}

const userStatusColors: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: '#4caf50', // Green
  [UserStatus.SUSPENDED]: '#ff5722', // Deep Orange
  [UserStatus.DEACTIVATED]: '#616161', // Dark Grey
  [UserStatus.PENDING]: '#673ab7', // Purple
}

const userStatusBackground: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: '#e8f5e9', // Light Green
  [UserStatus.SUSPENDED]: '#fbe9e7', // Light Deep Orange
  [UserStatus.DEACTIVATED]: '#efefef', // Light Grey
  [UserStatus.PENDING]: '#ede7f6', // Light Purple
}

const UserStatusField: FC<{source: string}> = ({source}) => {
  const record = useRecordContext()
  const status = record[source] as UserStatus

  const color = useMemo(() => userStatusColors[status] || '#000', [status])
  const backgroundColor = useMemo(
    () => userStatusBackground[status] || '#fff',
    [status],
  )
  const displayName = useMemo(
    () => userStatusDisplayNames[status] || status,
    [status],
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center', // Vertically centers the text
        justifyContent: 'center', // Horizontally centers the text
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        padding: '5px',
      }}>
      <Typography fontSize={10} style={{color: color}}>
        {displayName}
      </Typography>
    </div>
  )
}

export default UserStatusField
