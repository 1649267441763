import {Tab, Tabs} from '@mui/material'
import {useState} from 'react'
import {AccountsChart} from './chart'
import './index.css'

export const AccountsWidget = ({accounts}: any) => {
  const [value, setValue] = useState('all')

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div className="accounts-wg-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div className="accounts-wg-label">Accounts status overview</div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="customTabs">
          <Tab label="All" value="all" className="customTab" />
          <Tab label="Personal" value="personal" className="customTab" />
          <Tab label="FOP" value="entrepreneur" className="customTab" />
        </Tabs>
      </div>

      <div className="accounts-chart-container">
        <AccountsChart statistics={accounts[value] || {}} />
        <div className="accounts-chart-labels">
          <StatusComponent
            label="Active"
            value={accounts[value]?.active || 0}
            color="#44B461"
          />
          <StatusComponent
            label="Suspended"
            value={accounts[value]?.suspended || 0}
            color="#F59E0B"
          />
          <StatusComponent
            label="Closed"
            value={accounts[value]?.closed || 0}
            color="#EF4444"
          />
        </div>
      </div>
    </div>
  )
}

function StatusComponent({label, value, color = '#44B461'}: any) {
  return (
    <div className="status-wrapper">
      <div className="status-inner">
        <div className="status-dot" style={{background: color}} />
        <div className="status-text">{label}</div>
        <div className="status-text">-</div>
        <div className="status-number">{value}</div>
      </div>
    </div>
  )
}
