import {Cell, Pie, PieChart, Tooltip} from 'recharts'

const COLORS = ['#44B461', '#F59E0B', '#EF4444']

export const AccountsChart = ({statistics}: any) => {
  const size = 160
  const margin = 10
  const outerRadius = size / 2 - margin
  const innerRadius = outerRadius - 36

  const data = [
    {name: 'Active', value: statistics.active || 0},
    {name: 'Suspended', value: statistics.suspended || 0},
    {name: 'Closed', value: statistics.closed || 0},
  ]

  return (
    <PieChart width={size} height={size}>
      <Pie
        data={data}
        cx={size / 2}
        cy={size / 2}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={90}
        endAngle={-270}
        label={false}
        dataKey="value"
        nameKey="name">
        {data.map((entry, index) => (
          <Cell
            style={{outline: 'none'}}
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
      <Tooltip
        cursor={{fill: 'transparent'}}
        contentStyle={{
          color: 'white',
          borderRadius: 8,
        }}
      />
    </PieChart>
  )
}
