import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import {UserMenu as DefaultUserMenu, MenuItemLink, useLogout} from 'react-admin'
import {ProfileSVG} from './icons'

export const UserMenu = (props: any) => {
  const logout = useLogout()

  const handleLogout = () => {
    logout()
  }

  return (
    <DefaultUserMenu {...props} icon={<ProfileSVG />}>
      <MenuItemLink
        to="/my-profile"
        primaryText="Profile Page"
        leftIcon={<SettingsIcon />} // Replace IconProfile with your desired icon component
      />
      <MenuItemLink
        to="/login"
        primaryText="Logout"
        onClick={handleLogout}
        leftIcon={<LogoutIcon />} // Replace IconLogout with your desired icon component
      />
    </DefaultUserMenu>
  )
}
