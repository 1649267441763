import {Tooltip, Typography} from '@mui/material'
import {FC, ReactNode, useMemo} from 'react'
import {useRecordContext} from 'react-admin'
import {EEACountries} from '../../../helpers/eu-countries'

export enum AddressStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  NOT_VALIDATED = 'not_validated',
}

const addressStatusDisplayNames: Record<AddressStatus, string> = {
  [AddressStatus.VALID]: 'Valid',
  [AddressStatus.INVALID]: 'Invalid',
  [AddressStatus.NOT_VALIDATED]: 'Not Validated',
}

const addressStatusColors: Record<AddressStatus, string> = {
  [AddressStatus.VALID]: '#4caf50',
  [AddressStatus.INVALID]: '#f44336',
  [AddressStatus.NOT_VALIDATED]: '#9e9e9e',
}

const addressStatusBackground: Record<AddressStatus, string> = {
  [AddressStatus.VALID]: '#e8f5e9',
  [AddressStatus.INVALID]: '#ffebee',
  [AddressStatus.NOT_VALIDATED]: '#fafafa',
}

interface AddressStatusFieldProps {
  source: string
  label?: ReactNode
}

const AddressStatusField: FC<AddressStatusFieldProps> = () => {
  const record = useRecordContext()
  const status = record?.address?.status as AddressStatus
  const city = record?.address?.city
  const isoCountryCode = useMemo(
    () =>
      EEACountries.find(
        country => country.iso3 === record?.address?.isoCountryCode,
      ),
    [record?.address?.isoCountryCode],
  )

  const color = useMemo(() => addressStatusColors[status] || '#000', [status])
  const backgroundColor = useMemo(
    () => addressStatusBackground[status] || 'transparent',
    [status],
  )

  const displayName = useMemo(
    () =>
      `${addressStatusDisplayNames[status] || status} - ${city}, ${
        isoCountryCode?.name
      }`,
    [status],
  )

  if (!city || !isoCountryCode) return null

  return (
    <Tooltip title={displayName}>
      <div style={{flexDirection: 'row', display: 'flex'}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
            width: 16,
            height: 16,
            background: backgroundColor,
            borderRadius: 4,
          }}>
          <div
            style={{
              width: 6,
              height: 6,
              background: color,
              borderRadius: 9999,
            }}
          />
        </div>
        <Typography
          sx={{
            maxWidth: 125,
            fontSize: 12,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
          }}>
          {city}, {isoCountryCode.name}
        </Typography>
      </div>
    </Tooltip>
  )
}

export default AddressStatusField
