import './index.css'

export const TransactionType = ({type, percent, color}: any) => {
  return (
    <div className="wg-transaction-type">
      <div
        className="wg-transaction-type-icon"
        style={{backgroundColor: color}}
      />
      <div className="wg-transaction-type-container">
        <div className="wg-transaction-type-text">
          <div>{type}</div>
          <div>{percent}%</div>
        </div>
        <div className="wg-transaction-type-status">
          <div
            className="wg-transaction-type-percent"
            style={{backgroundColor: color, width: `${percent}%`}}
          />
        </div>
      </div>
    </div>
  )
}
