// SidePanel.js

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {FieldWithValue} from '../../../components'

export const CardsTab = ({record}: any) => {
  return (
    record?.cards && (
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Trunc PAN</TableCell>
                <TableCell align="left">Expiry</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Scheme</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Pin Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.cards.map((card: any) => (
                <CardRow key={card.id} row={card} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  )
}

const CardRow = (props: any) => {
  const {row} = props
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          '&:hover': {
            backgroundColor: '#E8E8E8',
            cursor: 'pointer', // Change this to the darker color you desire
          },
        }}
        onClick={() => setOpen(!open)}>
        <TableCell align="left">{row.truncatedPan}</TableCell>
        <TableCell align="left">{row.expiryDate}</TableCell>
        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left">{row.scheme}</TableCell>
        <TableCell align="left">{row.status.value}</TableCell>
        <TableCell align="left">{row.pinStatus}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h5" gutterBottom component="div">
                  Details
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <div style={{margin: '10px'}}>
                    <FieldWithValue fieldName="Id" fieldValue={row.id} />
                    <FieldWithValue fieldName="Id" fieldValue={row.id} />
                    <FieldWithValue
                      fieldName="Ledger Id"
                      fieldValue={row.ledgerId}
                    />
                    <FieldWithValue
                      fieldName="Profile Name"
                      fieldValue={row.profile.name}
                    />
                    <FieldWithValue
                      fieldName="Creation Date"
                      fieldValue={row.creationDate}
                    />
                  </div>
                </div>
              </Box>
              <Box style={{display: 'flex', flexDirection: 'column'}}></Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
