export const CardsSVG = ({size = 24}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="6" fill="#E8F2FA" />
    <path
      d="M26 16H22C18.2288 16 16.3431 16 15.1716 17.1716C14.328 18.0151 14.0918 19.2288 14.0257 21.25H33.9743C33.9082 19.2288 33.672 18.0151 32.8284 17.1716C31.6569 16 29.7712 16 26 16Z"
      fill="#1782CA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 32H26C29.7712 32 31.6569 32 32.8284 30.8284C34 29.6569 34 27.7712 34 24C34 23.5581 34 23.142 33.9981 22.75H14.0019C14 23.142 14 23.5581 14 24C14 27.7712 14 29.6569 15.1716 30.8284C16.3431 32 18.2288 32 22 32ZM18 27.25C17.5858 27.25 17.25 27.5858 17.25 28C17.25 28.4142 17.5858 28.75 18 28.75H22C22.4142 28.75 22.75 28.4142 22.75 28C22.75 27.5858 22.4142 27.25 22 27.25H18ZM23.75 28C23.75 27.5858 24.0858 27.25 24.5 27.25H26C26.4142 27.25 26.75 27.5858 26.75 28C26.75 28.4142 26.4142 28.75 26 28.75H24.5C24.0858 28.75 23.75 28.4142 23.75 28Z"
      fill="#1782CA"
    />
  </svg>
)
