import {Typography} from '@mui/material'
import {FC, ReactNode, useMemo} from 'react'
import {useRecordContext} from 'react-admin'
type TransactionStatus = 'Processing' | 'Done' | 'Cancelled' | 'Pending'

const statusDisplayNames: Record<TransactionStatus, string> = {
  Processing: 'Processing',
  Pending: 'Pending',
  Done: 'Done',
  Cancelled: 'Cancelled',
}

const statusColors: Record<TransactionStatus, string> = {
  Done: '#4caf50', // Green
  Cancelled: '#f44336', // Red
  Pending: '#2196f3', // Blue
  Processing: '#F7C71D', //Yellow
}

const statusBackground: Record<TransactionStatus, string> = {
  Done: '#e8f5e9', // Light Green
  Cancelled: '#ffebee', // Light Red
  Pending: '#e3f2fd', // Light Blue
  Processing: '#FFE06F', //Light Yellow
}

interface TransactionStatusFieldProps {
  source: string
  label?: ReactNode // This can be a string, element, etc.
}

const TransactionStatusField: FC<TransactionStatusFieldProps> = ({source}) => {
  const record = useRecordContext()
  const status = record[source] as TransactionStatus

  const color = useMemo(() => statusColors[status] || '#000', [status])
  const backgroundColor = useMemo(
    () => statusBackground[status] || '#fff',
    [status],
  )
  const displayName = useMemo(
    () => statusDisplayNames[status] || status,
    [status],
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center', // Vertically centers the text
        justifyContent: 'center', // Horizontally centers the text
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        padding: '5px',
      }}>
      <Typography fontSize={10} style={{color: color}}>
        {displayName}
      </Typography>
    </div>
  )
}

export default TransactionStatusField
