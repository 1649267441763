import {DateField, EmailField, SimpleShowLayout, TextField} from 'react-admin'
import {PhoneNumberWithFlagField} from '../../../components'

export const InfoTab = ({record}: any) => {
  return (
    <SimpleShowLayout record={record}>
      <TextField source="firstName" />
      <TextField source="lastName" />
      {/* <TextField source="middleName" />
      <TextField source="gender" /> */}
      <TextField source="id" label="Id" />
      <EmailField source="email" />
      {/* <PhoneNumberWithFlag record={record} /> */}
      <PhoneNumberWithFlagField source="phoneNumber" label="Phone Number" />
      <DateField source="birthDate" />
      <TextField source="status" />
      <TextField source="kycStatus" />
      {/* <BooleanField source="emailVerified" /> */}
      <TextField source="referralCode" label="Referal Code" />
      <TextField source="registrationIp" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <DateField source="lastOnline" showTime />
    </SimpleShowLayout>
  )
}
