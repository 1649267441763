// SidePanel.js

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {FieldWithValue} from '../../../components'

export const AccountsTab = ({record}: any) => {
  return (
    record?.accounts && (
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">IBAN</TableCell>
                <TableCell align="left">BIC</TableCell>
                <TableCell align="left">Balance</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.accounts.map((account: any) => (
                <AccountRow key={account.id} row={account} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  )
}

const AccountRow = (props: any) => {
  const {row} = props
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          '&:hover': {
            backgroundColor: '#E8E8E8',
            cursor: 'pointer', // Change this to the darker color you desire
          },
        }}
        onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {row.iban}
        </TableCell>
        <TableCell align="left">{row.bic}</TableCell>
        <TableCell align="left">
          {row.balance} {row.currency}
        </TableCell>
        <TableCell align="left">{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{margin: 1}}
              style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div>
                  <FieldWithValue fieldName="Id" fieldValue={row.id} />
                  <FieldWithValue
                    fieldName="Provider Id"
                    fieldValue={row.providerId}
                  />
                  <FieldWithValue
                    fieldName="Provider"
                    fieldValue={row.provider}
                  />
                </div>
                <div style={{margin: '10px'}}>
                  <FieldWithValue
                    fieldName="Created At"
                    fieldValue={row.createdAt}
                  />
                  <FieldWithValue
                    fieldName="Updated At"
                    fieldValue={row.updatedAt}
                  />
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
