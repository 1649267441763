export const EEACountries = [
  {name: 'Austria', code: 'AT', iso3: 'AUT'},
  {name: 'Belgium', code: 'BE', iso3: 'BEL'},
  {name: 'Bulgaria', code: 'BG', iso3: 'BGR'},
  {name: 'Croatia', code: 'HR', iso3: 'HRV'},
  {name: 'Czech Republic', code: 'CZ', iso3: 'CZE'},
  {name: 'Denmark', code: 'DK', iso3: 'DNK'},
  {name: 'Estonia', code: 'EE', iso3: 'EST'},
  {name: 'Finland', code: 'FI', iso3: 'FIN'},
  {name: 'France', code: 'FR', iso3: 'FRA'},
  {name: 'Germany', code: 'DE', iso3: 'DEU'},
  {name: 'Greece', code: 'GR', iso3: 'GRC'},
  {name: 'Hungary', code: 'HU', iso3: 'HUN'},
  {name: 'Iceland', code: 'IS', iso3: 'ISL'},
  {name: 'Ireland', code: 'IE', iso3: 'IRL'},
  {name: 'Italy', code: 'IT', iso3: 'ITA'},
  {name: 'Latvia', code: 'LV', iso3: 'LVA'},
  {name: 'Liechtenstein', code: 'LI', iso3: 'LIE'},
  {name: 'Lithuania', code: 'LT', iso3: 'LTU'},
  {name: 'Luxembourg', code: 'LU', iso3: 'LUX'},
  {name: 'Malta', code: 'MT', iso3: 'MLT'},
  {name: 'Netherlands', code: 'NL', iso3: 'NLD'},
  {name: 'Norway', code: 'NO', iso3: 'NOR'},
  {name: 'Poland', code: 'PL', iso3: 'POL'},
  {name: 'Portugal', code: 'PT', iso3: 'PRT'},
  {name: 'Republic of Cyprus', code: 'CY', iso3: 'CYP'},
  {name: 'Romania', code: 'RO', iso3: 'ROU'},
  {name: 'Slovakia', code: 'SK', iso3: 'SVK'},
  {name: 'Slovenia', code: 'SI', iso3: 'SVN'},
  {name: 'Spain', code: 'ES', iso3: 'ESP'},
  {name: 'Sweden', code: 'SE', iso3: 'SWE'},
  {name: 'United Kingdom', code: 'GB', iso3: 'GBR'},
]
