import {
  Create,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const AccountCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="firstName" isRequired />
      <TextInput source="lastName" isRequired />
      <TextInput type="email" source="email" isRequired />
      <SelectInput
        source="role"
        choices={[
          {id: 'Admin', name: 'Admin'},
          {id: 'Support', name: 'Support'},
        ]}
        isRequired
      />
      <PasswordInput source="password" />
      <PasswordInput source="password2" />
    </SimpleForm>
  </Create>
);
