import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {useEffect, useState} from 'react'

import {useRedirect} from 'react-admin'
import {
  AccountsWidget,
  RegistrationWidget,
  Statistics,
  TransactionsWidget,
} from './components'
import './dashboard.css'
import {CalendarSVG} from './icons/datepicker'

const fetchStatistics = async (startDate: any, endDate: any) => {
  const response = await fetch(
    `api/dashboard/statistics?` +
      new URLSearchParams({
        startDate,
        endDate,
      }),
    {
      method: 'GET',
      headers: new Headers({
        Authorization: localStorage.getItem('Authorization') as any,
      }),
      redirect: 'follow',
    },
  ).then(response => response.json())

  return response
}

export const Dashboard = () => {
  const redirect = useRedirect()
  const [type, setType] = useState('consumers')
  const [startDate, setStartDate] = useState<any>(dayjs('2023-08-15T00:00:00'))
  const [endDate, setEndDate] = useState<any>(dayjs().add(1, 'day'))
  const [statistics, setStatistics] = useState<any>({})

  useEffect(() => {
    async function fetchData() {
      try {
        const dashboardStatistics = await fetchStatistics(startDate, endDate)

        if (dashboardStatistics.statusCode === 401) {
          redirect('/login')

          return null
        }

        setStatistics(dashboardStatistics)
      } catch (error: any) {
        if (error.statusCode === 401) {
          redirect('/login')
        }
      }
    }
    fetchData()
  }, [startDate, endDate])

  console.log(statistics, ' = statistics')

  return (
    <div className="dashboard-container">
      <div className="dashboard-tools-row">
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="dt"
              slots={{
                openPickerIcon: () => <CalendarSVG />,
              }}
              value={startDate}
              onChange={setStartDate}
            />
            <DatePicker
              slots={{
                openPickerIcon: () => <CalendarSVG />,
              }}
              value={endDate}
              onChange={setEndDate}
            />
          </LocalizationProvider>
        </div>

        <div>
          <ToggleButtonGroup color="primary" value={type} exclusive>
            <ToggleButton
              onClick={() => {
                setType('consumers')
              }}
              value="consumers">
              Consumers
            </ToggleButton>
            <ToggleButton
              onClick={() => {
                setType('corporate')
              }}
              value="corporate">
              Corporates
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <Statistics
        {...{
          dataType: type,
          consumers: statistics.consumers?.active,
          corporates: 1,
          accounts: {
            consumers: statistics.accounts?.total,
            corporates: 2,
          },
          transactions: {
            consumers: statistics.transactions?.total,
            corporates: 0,
          },
        }}
      />
      {type === 'consumers' && (
        <div
          className="dashboard-wg-container mt-16"
          style={{userSelect: 'none', WebkitTapHighlightColor: 'transparent'}}>
          <RegistrationWidget consumers={statistics.consumers || {}} />
          <AccountsWidget accounts={statistics.accounts || {}} />
          <TransactionsWidget transactions={statistics.transactions || {}} />
        </div>
      )}
    </div>
  )
}
