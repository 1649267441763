export const DashboardSVG = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M5.8335 8.33301C5.8335 6.91879 5.8335 6.21169 6.27284 5.77235C6.71218 5.33301 7.41928 5.33301 8.8335 5.33301C10.2477 5.33301 10.9548 5.33301 11.3942 5.77235C11.8335 6.21169 11.8335 6.91879 11.8335 8.33301C11.8335 9.74722 11.8335 10.4543 11.3942 10.8937C10.9548 11.333 10.2477 11.333 8.8335 11.333C7.41928 11.333 6.71218 11.333 6.27284 10.8937C5.8335 10.4543 5.8335 9.74722 5.8335 8.33301Z"
      fill="#1782CA"
    />
    <path
      d="M13.1665 15.667C13.1665 14.2528 13.1665 13.5457 13.6058 13.1063C14.0452 12.667 14.7523 12.667 16.1665 12.667C17.5807 12.667 18.2878 12.667 18.7272 13.1063C19.1665 13.5457 19.1665 14.2528 19.1665 15.667C19.1665 17.0812 19.1665 17.7883 18.7272 18.2277C18.2878 18.667 17.5807 18.667 16.1665 18.667C14.7523 18.667 14.0452 18.667 13.6058 18.2277C13.1665 17.7883 13.1665 17.0812 13.1665 15.667Z"
      fill="#1782CA"
    />
    <path
      d="M5.8335 15.667C5.8335 14.2528 5.8335 13.5457 6.27284 13.1063C6.71218 12.667 7.41928 12.667 8.8335 12.667C10.2477 12.667 10.9548 12.667 11.3942 13.1063C11.8335 13.5457 11.8335 14.2528 11.8335 15.667C11.8335 17.0812 11.8335 17.7883 11.3942 18.2277C10.9548 18.667 10.2477 18.667 8.8335 18.667C7.41928 18.667 6.71218 18.667 6.27284 18.2277C5.8335 17.7883 5.8335 17.0812 5.8335 15.667Z"
      fill="#1782CA"
    />
    <path
      d="M13.1665 8.33301C13.1665 6.91879 13.1665 6.21169 13.6058 5.77235C14.0452 5.33301 14.7523 5.33301 16.1665 5.33301C17.5807 5.33301 18.2878 5.33301 18.7272 5.77235C19.1665 6.21169 19.1665 6.91879 19.1665 8.33301C19.1665 9.74722 19.1665 10.4543 18.7272 10.8937C18.2878 11.333 17.5807 11.333 16.1665 11.333C14.7523 11.333 14.0452 11.333 13.6058 10.8937C13.1665 10.4543 13.1665 9.74722 13.1665 8.33301Z"
      fill="#1782CA"
    />
  </svg>
)
