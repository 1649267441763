import {Edit, SimpleForm, TextInput, SelectInput} from 'react-admin';
import {useParams} from 'react-router';
import {useEffect, useState} from 'react';

export const AdminEdit = props => {
  const {id} = useParams();
  const [isSameUser, setIsSameUser] = useState(false);
  useEffect(() => {
    async function fetchUserData() {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', localStorage.getItem('Authorization'));

      const response = await fetch(`api/admins/profile`, {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      })
        .then(response => response.json())
        .catch(error => console.log('error', error));
        console.log(response, id, "response.id and id")
      if (response.id == id) setIsSameUser(true);
    }
    fetchUserData();
  }, [id]); // re-run the effect when the id changes
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="firstName" isRequired />
        <TextInput source="lastName" isRequired />
        <TextInput type="email" source="email" isRequired />
        {isSameUser ? (
          <SelectInput
            source="role"
            choices={[
              {id: 'Admin', name: 'Admin'},
              {id: 'Support', name: 'Support'},
            ]}
            disabled
          />
        ) : (
          <SelectInput
            source="role"
            choices={[
              {id: 'Admin', name: 'Admin'},
              {id: 'Support', name: 'Support'},
            ]}
          />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default AdminEdit;
