import {Typography} from '@mui/material'
import {FC, ReactNode, useMemo} from 'react'
import {useRecordContext} from 'react-admin'
type KYCStatus =
  | 'validated'
  | 'invalid'
  | 'not_started'
  | 'in_progress'
  | 'pending'
  | 'expired'
  | 'not_fully_verified' // Newly added status

const statusDisplayNames: Record<KYCStatus, string> = {
  validated: 'Validated',
  invalid: 'Invalid',
  not_started: 'Not Started',
  in_progress: 'In Progress',
  pending: 'Pending',
  expired: 'Expired',
  not_fully_verified: 'Not Fully Verified', // Newly added status
}

const statusColors: Record<KYCStatus, string> = {
  validated: '#4caf50', // Green
  invalid: '#f44336', // Red
  not_started: '#9e9e9e', // Grey
  in_progress: '#ff9800', // Orange
  pending: '#2196f3', // Blue
  expired: '#607d8b', // Blue Grey
  not_fully_verified: '#ff5722', // Lime  // Newly added status
}

const statusBackground: Record<KYCStatus, string> = {
  validated: '#e8f5e9', // Light Green
  invalid: '#ffebee', // Light Red
  not_started: '#fafafa', // Light Grey
  in_progress: '#fff3e0', // Light Orange
  pending: '#e3f2fd', // Light Blue
  expired: '#eceff1', // Light Blue Grey
  not_fully_verified: '#fbe9e7', // Light Lime  // Newly added status
}

interface KYCStatusFieldProps {
  source: string
  label?: ReactNode // This can be a string, element, etc.
}

const KYCStatusField: FC<KYCStatusFieldProps> = ({source}) => {
  const record = useRecordContext()
  const status = record[source] as KYCStatus

  const color = useMemo(() => statusColors[status] || '#000', [status])
  const backgroundColor = useMemo(
    () => statusBackground[status] || '#fff',
    [status],
  )
  const displayName = useMemo(
    () => statusDisplayNames[status] || status,
    [status],
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center', // Vertically centers the text
        justifyContent: 'center', // Horizontally centers the text
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        padding: '5px',
      }}>
      <Typography fontSize={10} style={{color: color}}>
        {displayName}
      </Typography>
    </div>
  )
}

export default KYCStatusField
