import {DateField, SimpleShowLayout, TextField} from 'react-admin'

// id: '123e4567-e89b-12d3-a456-426614174000',

// // Assuming AccountEntity, UserCorporateEntity, BenificiaryEntity, TransactionEntity have been defined elsewhere
// accounts: [], // Populate with instances of AccountEntity if needed
// userCorporates: [], // Populate with instances of UserCorporateEntity if needed
// benificiaries: [], // Populate with instances of BenificiaryEntity if needed
// transactions: [], // Populate with instances of TransactionEntity if needed
// createdAt: new Date(), // Current date and time
// updatedAt: new Date(), // Current date and time

export const InfoTab = ({record}: any) => {
  return (
    <SimpleShowLayout record={record}>
      <TextField source="registeredName" />
      <TextField source="tradeName" />
      <TextField source="id" />
      <TextField source="incorporationCountry" />
      <TextField source="incorporationCode" />
      <TextField source="incorporationDate" />
      <TextField source="legalType" />
      <TextField source="website" />
      <TextField source="industry" />
      <TextField source="registeredAddress" />
      <TextField source="expectedMonthlyExpenses" />
      <TextField source="employeesRange" />

      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  )
}
