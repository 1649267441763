export const CloseSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.51168 4.43057C6.82617 4.161 7.29965 4.19743 7.56921 4.51192L13.5692 11.5119C13.81 11.7928 13.81 12.2072 13.5692 12.4881L7.56921 19.4881C7.29965 19.8026 6.82617 19.839 6.51168 19.5695C6.19718 19.2999 6.16076 18.8264 6.43033 18.5119L12.012 12L6.43033 5.48811C6.16076 5.17361 6.19718 4.70014 6.51168 4.43057ZM10.5119 4.43068C10.8264 4.16111 11.2998 4.19753 11.5694 4.51202L17.5694 11.512C17.8102 11.7929 17.8102 12.2073 17.5694 12.4882L11.5694 19.4882C11.2998 19.8027 10.8264 19.8391 10.5119 19.5696C10.1974 19.3 10.161 18.8265 10.4305 18.512L16.0122 12.0001L10.4305 5.48821C10.161 5.17372 10.1974 4.70024 10.5119 4.43068Z"
      fill="#A0A8AF"
    />
  </svg>
)
