import {DateField, SimpleShowLayout, TextField} from 'react-admin'

export const InfoTab = ({record}: any) => {
  return (
    <SimpleShowLayout record={record}>
      <TextField source="id" label="Id" />
      <TextField source="userId" label="User Id" />
      <TextField source="iban" />
      <TextField source="bic" />
      <TextField source="balance" />
      <TextField source="currency" />
      <TextField source="type" />
      <TextField source="status" showTime />
      <TextField source="provider" />
      <TextField source="providerId" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  )
}
