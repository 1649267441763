import UserIcon from '@mui/icons-material/Person2Outlined' //Look for new icon

import {ConsumerCreate} from './ConsumerCreate'
import {ConsumersList} from './ConsumersList'

export default {
  list: ConsumersList,
  show: ConsumersList,
  icon: UserIcon,
  create: ConsumerCreate,
}
