import {Button, Grid} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import {
  DateField,
  Filter,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
} from 'react-admin'
import {useNavigate, useParams} from 'react-router-dom'
import {DataGridWithIndex} from '../../components'
import {CommonSidePanel} from '../../components/layout/CommonSidePanel'
import {CustomList} from '../../components/layout/CustomList'
import {exporter} from '../utils/exporter'
import TransactionAmountField from './fields/transaction-amount'
import TransactionStatusField from './fields/transaction-status'
import {InfoTab} from './tabs'

const fetchTransactionData = async (id: string, dataProvider: any) => {
  const entity: any = await dataProvider.getOne('transactions', {id: id})

  return entity
}

const downloadTransactionsData = () => {
  let collectedData: any[] = []
  const maxPerFetch: number = 100
  let totalRows: number

  const fetchBatch = async (page: number = 0) => {
    try {
      const authorizationToken = localStorage.getItem('Authorization')
      if (!authorizationToken) {
        throw new Error('Authorization token not found')
      }

      const myHeaders = new Headers({
        Authorization: authorizationToken,
        'Content-Type': 'application/json',
      })

      const offset = maxPerFetch * page
      const response = await fetch(
        `/api/transactions?` +
          new URLSearchParams({
            limit: `${maxPerFetch}`,
            offset: `${offset}`,
            sort: 'createdAt,DESC',
          }),
        {
          method: 'GET',
          headers: myHeaders,
        },
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const rawData = await response.json()
      const {data, total} = rawData
      collectedData = [...collectedData, ...data]
      totalRows = total
    } catch (error) {
      console.error('Fetch error:', error)
    }

    if (collectedData.length < totalRows) {
      await fetchBatch(page + 1)
    } else {
      exporter('transactions')(collectedData)
    }
  }

  fetchBatch().catch(err => console.error('Error during export:', err))
}

const ListActions = () => (
  <TopToolbar>
    <Button variant="text" onClick={downloadTransactionsData}>
      Export
    </Button>
  </TopToolbar>
)

export const TransactionsList = ({...props}) => {
  const params = useParams()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState<any>(null)
  const TransactionFilter = (props: any) => {
    return (
      <Filter {...props}>
        <TextInput label="Full Name" source="fullName" />
        <TextInput label="Currency" source="trnCurrencyCode" />
        <NumberInput label="Max Amount" source="maxAmount" />
        <NumberInput label="Min Amount" source="minAmount" />
        <SelectInput
          label="Payment Type"
          source="kind"
          choices={[
            {id: 'Bank Transfer', name: 'Bank Transfer'},
            {id: 'Contactless Purchase', name: 'Contactless Purchase'},
            {id: 'In-store Purchase', name: 'In-store Purchase'},
            {id: 'Cash withdrawal', name: 'Cash withdrawal'},
            {id: 'Contactless phone', name: 'Contactless phone'},
            {id: 'DiP transfer', name: 'DiP transfer'},
            {id: 'Face to face', name: 'Face to face'},
            {id: 'Online Purchase', name: 'Online Purchase'},
            {id: 'Move My Funds', name: 'Move My Funds'},
            {id: 'Fee', name: 'Fee'},
            {id: 'Payment transaction', name: 'Payment transaction'},
            {id: 'Balance Check at ATM', name: 'Balance Check at ATM'},
            {id: 'Pin Change in ATM', name: 'Pin Change in ATM'},
          ]}
        />
        <SelectInput
          label="Status"
          source="stateName"
          choices={[
            {id: 'Error', name: 'Error'},
            {id: 'Reversed', name: 'Reversed'},
            {id: 'Cancelled', name: 'Cancelled'},
            {id: 'Processing', name: 'Processing'},
            {id: 'Waiting other side', name: 'Waiting other side'},
            {id: 'Waiting for acceptance', name: 'Waiting for acceptance'},
            {id: 'Pending', name: 'Pending'},
            {id: 'Done', name: 'Done'},
          ]}
        />
      </Filter>
    )
  }
  useEffect(() => {
    const id = params['*']

    if (id) {
      fetchTransactionData(id, dataProvider).then(entity => {
        setRecord(entity)
      })
    }
  }, [params['*'], dataProvider])

  const handleRowClick = async (transactionId: any) => {
    return Promise.resolve(`/transactions/${transactionId}`)
  }

  const sidePanelTabsList = [{name: 'General', TabComponent: InfoTab}]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomList
          {...props}
          // customActions={['export']}
          actions={<ListActions />}
          filters={<TransactionFilter />}
          empty={false}
          sort={{field: 'createdAt', order: 'DESC'}}>
          <DataGridWithIndex {...props} rowClick={handleRowClick}>
            <TextField
              source="fullName"
              textAlign="left"
              label="Full Name"
              maxWidth={120}
              display="inline-flex"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            />
            {/* <ReceiverField source='receiver["name"]' label="Receiver" /> */}
            {/* <TextField
              source="type"
              textAlign="left"
              label="Type"
              maxWidth={60}
            /> */}
            <TextField source="kind" textAlign="left" label="Payment Type" />
            <TransactionAmountField source="trnAmount" label="Amount" />
            <TextField
              source="trnCurrencyCode"
              textAlign="left"
              label="TRN Currency"
            />
            <TransactionStatusField source="stateName" label="Status" />
            <DateField source="eventDate" textAlign="left" showTime />
            <DateField
              source="finishDate"
              textAlign="left"
              label="Clearing Date"
              showTime
            />
          </DataGridWithIndex>
        </CustomList>
      </Grid>
      <Grid item xs={4} md={3}>
        <Box display="flex" alignItems="flex-start" mt={8} height="100%">
          <CommonSidePanel
            title="Transaction"
            record={record}
            list={sidePanelTabsList}
            togglePanel={() => {
              setRecord(undefined)
              navigate('/transactions', {replace: true})
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
