import {Grid} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import {DateField, TextField, useDataProvider} from 'react-admin'
import {useNavigate, useParams} from 'react-router-dom'
import {DataGridWithIndex} from '../../components'
import {CommonSidePanel} from '../../components/layout/CommonSidePanel'
import {CustomList} from '../../components/layout/CustomList'
import {
  AccountsTab,
  FlagrightTab,
  IdenfyTab,
  InfoTab,
  TransactionsTab,
} from './tabs'

const fetchConsumerData = async (id: string, dataProvider: any) => {
  const entity: any = await dataProvider.getOne('corporates', {id: id})

  // const linkcyId = entity['data']['linkcyId']
  // const myHeaders = new Headers()
  // myHeaders.append('Authorization', localStorage.getItem('Authorization') ?? '')

  // if (linkcyId) {
  //   const [consumerCards, consumerTransactions] = await Promise.all([
  //     fetch(`api/corporates/${linkcyId}/cards`, {
  //       method: 'GET',
  //       headers: myHeaders,
  //     }).then(response => response.json()),
  //     fetch(`api/corporates/${linkcyId}/transactions`, {
  //       method: 'GET',
  //       headers: myHeaders,
  //     }).then(response => response.json()),
  //   ])
  //   entity['data']['transactions'] = consumerTransactions['content']
  //   entity['data']['cards'] = consumerCards
  // }

  return entity
}

export const CorporateList = ({...props}) => {
  const params = useParams()
  const navigate = useNavigate()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState<any>(null)

  useEffect(() => {
    const id = params['*']

    if (id) {
      fetchConsumerData(id, dataProvider).then(entity => {
        setRecord(entity)
      })
    }
  }, [params['*'], dataProvider])

  const handleRowClick = async (consumerId: any) => {
    return `/corporates/${consumerId}`
  }

  const sidePanelTabsList = [
    {name: 'General', TabComponent: InfoTab},
    {name: 'Accounts', TabComponent: AccountsTab},
    {name: 'Transactions', TabComponent: TransactionsTab},
    {name: 'Idenfy', TabComponent: IdenfyTab},
    {name: 'Flagright', TabComponent: FlagrightTab},
  ]

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomList
          {...props}
          customActions={['export']}
          sort={{field: 'createdAt', order: 'DESC'}}>
          <DataGridWithIndex
            {...props}
            bulkActionButtons={false}
            style={{zIndex: 0}}
            rowClick={handleRowClick}>
            <TextField fontSize={12} source="registeredName" maxWidth={80} />
            <TextField fontSize={12} source="tradeName" maxWidth={80} />
            <TextField fontSize={12} source="incorporationCountry" />
            <TextField fontSize={12} source="incorporationCode" />
            <DateField fontSize={12} source="createdAt" />
          </DataGridWithIndex>
        </CustomList>
      </Grid>
      <Grid item xs={4} md={3}>
        <Box display="flex" alignItems="flex-start" mt={8} height="100%">
          <CommonSidePanel
            title="Corporate Profile"
            record={record}
            list={sidePanelTabsList}
            togglePanel={() => {
              setRecord(undefined)
              navigate('/corporates', {replace: true})
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
