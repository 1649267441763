import apiProvider from '../crud'

export const dataProvider = apiProvider('/api')
// A function decorating a dataProvider for handling user profiles
const addUserProfileOverrides = (dataProvider: any) => ({
  ...dataProvider,
  getUserProfile () {
    const myHeaders = new Headers()
    myHeaders.append(
      'Authorization',
      localStorage.getItem('Authorization') ?? ''
    )

    //var requestOptions = ;
    const userId = localStorage.getItem('userId')
    const response = fetch(`/api/user/${userId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
    const profile = JSON.parse(response as unknown as string)['result']
    if (!profile) {
      return Promise.resolve({ data: {} })
    }
    const data = JSON.parse(profile)
    console.log('getUserProfile', data)
    return Promise.resolve({ data })
  },
  async updateUserProfile ({ data }: any) {
    // Convert a newly uploaded file to b64
    const myHeaders = new Headers()
    myHeaders.append(
      'Authorization',
      localStorage.getItem('Authorization') ?? ''
    )

    //var requestOptions = ;
    const userId = localStorage.getItem('userId')
    const response = fetch(`/api/user/${userId}`, {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: data
    })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error))
    //const profile = JSON.parse(response as unknown as string)['result'];

    //TODO please check if this is valid
    return Promise.resolve({ data: response })
  }
})

export default addUserProfileOverrides(apiProvider('/api'))
