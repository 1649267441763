export const ClientsSVG = ({size = 24}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M14.8332 9.00033C14.8332 10.289 13.7885 11.3337 12.4998 11.3337C11.2112 11.3337 10.1665 10.289 10.1665 9.00033C10.1665 7.71166 11.2112 6.66699 12.4998 6.66699C13.7885 6.66699 14.8332 7.71166 14.8332 9.00033Z"
      fill="#1782CA"
    />
    <path
      d="M16.5 15.0003C16.5 16.289 14.7091 17.3337 12.5 17.3337C10.2909 17.3337 8.5 16.289 8.5 15.0003C8.5 13.7117 10.2909 12.667 12.5 12.667C14.7091 12.667 16.5 13.7117 16.5 15.0003Z"
      fill="#1782CA"
    />
    <path
      d="M9.24819 7.33301C9.3665 7.33301 9.482 7.34462 9.59353 7.36672C9.32182 7.84931 9.16683 8.40639 9.16683 8.99967C9.16683 9.57851 9.31437 10.1229 9.57389 10.5972C9.46846 10.6169 9.35957 10.6272 9.24819 10.6272C8.30525 10.6272 7.54085 9.88976 7.54085 8.9801C7.54085 8.07043 8.30525 7.33301 9.24819 7.33301Z"
      fill="#1782CA"
    />
    <path
      d="M8.13172 16.657C7.75311 16.2044 7.50016 15.649 7.50016 14.9997C7.50016 14.3702 7.73788 13.829 8.09735 13.3842C6.82756 13.4827 5.8335 14.1771 5.8335 15.0193C5.8335 15.8693 6.84503 16.5689 8.13172 16.657Z"
      fill="#1782CA"
    />
    <path
      d="M15.8334 8.99967C15.8334 9.57851 15.6859 10.1229 15.4264 10.5972C15.5318 10.6169 15.6407 10.6272 15.7521 10.6272C16.695 10.6272 17.4594 9.88976 17.4594 8.9801C17.4594 8.07043 16.695 7.33301 15.7521 7.33301C15.6338 7.33301 15.5183 7.34462 15.4067 7.36672C15.6784 7.84931 15.8334 8.40639 15.8334 8.99967Z"
      fill="#1782CA"
    />
    <path
      d="M16.8685 16.657C18.1552 16.5689 19.1668 15.8693 19.1668 15.0193C19.1668 14.1771 18.1727 13.4827 16.9029 13.3842C17.2624 13.829 17.5001 14.3702 17.5001 14.9997C17.5001 15.649 17.2472 16.2044 16.8685 16.657Z"
      fill="#1782CA"
    />
  </svg>
)
