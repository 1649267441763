import {
  AccountsSVG,
  BusinessSVG,
  CardsSVG,
  ClientsSVG,
  TransactionsSVG,
} from '../../../../components/layout/icons'
import './index.css'

const StatisticItem = ({icon, label, value}: any) => {
  return (
    <div className="statistic-item">
      {icon}
      <div>
        <p className="statistic-label">{label}</p>
        <span className="statistic-value">{value}</span>
      </div>
    </div>
  )
}

export const Statistics = ({
  dataType,
  consumers,
  corporates,
  accounts,
  transactions,
}: any) => {
  return (
    <div className="statistics-row">
      {dataType === 'consumers' ? (
        <StatisticItem
          label="Consumers"
          value={consumers || 0}
          icon={<ClientsSVG size={48} />}
        />
      ) : (
        <StatisticItem
          label="Corporates"
          value={corporates || 0}
          icon={<BusinessSVG size={48} />}
        />
      )}
      <StatisticItem
        label="Accounts"
        value={
          (dataType === 'consumers'
            ? accounts.consumers
            : accounts.corporates) || 0
        }
        icon={<AccountsSVG size={48} />}
      />
      <StatisticItem label="Cards" value={0} icon={<CardsSVG size={48} />} />
      <StatisticItem
        label="Transactions"
        value={
          (dataType === 'consumers'
            ? transactions.consumers
            : transactions.corporates) || 0
        }
        icon={<TransactionsSVG size={48} />}
      />
    </div>
  )
}
