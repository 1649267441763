import {Box, Tab, Tabs, Toolbar} from '@mui/material'
import {AppBarProps} from 'react-admin'
import {Link, useLocation} from 'react-router-dom'
import {Logo} from './../logo'

import './app-bar.css'

import {
  AccountsSVG,
  AdminsSVG,
  BusinessSVG,
  ClientsSVG,
  DashboardSVG,
  TransactionsSVG,
} from './icons'
import {UserMenu} from './user-menu'

const tabPathMap = {
  dashboard: '/',
  consumers: '/consumers',
  corporates: '/corporates',
  accounts: '/accounts',
  transactions: '/transactions',
  admins: '/admins',
}

export const AppBar = (props: AppBarProps) => {
  const location = useLocation()
  const currentTab = Object.keys(tabPathMap).find(key => {
    //@ts-ignore
    const path = tabPathMap[key]

    return path === '/'
      ? path === location.pathname
      : location.pathname.includes(path)
  })

  const getTabSx = (tabValue: string) => ({
    '&.Mui-selected': {
      color: '#1782CA !important',
      backgroundColor: currentTab === tabValue ? '#F3F9FC' : 'white',
    },
  })

  return (
    <>
      <div className="app-bar-container">
        <Link to="/" style={{height: '32px'}}>
          <Logo height={32} />
        </Link>
        <Toolbar
          style={{
            minHeight: '48px',
            justifyContent: 'space-between',
          }}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto">
            <Tab
              sx={getTabSx('dashboard')}
              className="app-bar-item"
              component={Link}
              to="/"
              icon={<DashboardSVG />}
              label="Dashboard"
              value="dashboard"
            />
            <Tab
              sx={getTabSx('consumers')}
              className="app-bar-item"
              component={Link}
              to="/consumers"
              icon={<ClientsSVG />}
              label="Consumers"
              value="consumers"
            />
            <Tab
              sx={getTabSx('corporates')}
              className="app-bar-item"
              component={Link}
              to="/corporates"
              icon={<BusinessSVG />}
              label="Corporates"
              value="corporates"
            />
            <Tab
              sx={getTabSx('accounts')}
              className="app-bar-item"
              component={Link}
              to="/accounts"
              icon={<AccountsSVG />}
              label="Accounts"
              value="accounts"
            />
            <Tab
              sx={getTabSx('transactions')}
              className="app-bar-item"
              component={Link}
              to="/transactions"
              icon={<TransactionsSVG />}
              label="Transactions"
              value="transactions"
            />
            <Tab
              sx={getTabSx('admins')}
              className="app-bar-item"
              component={Link}
              to="/admins"
              icon={<AdminsSVG />}
              label="Admins"
              value="admins"
            />
          </Tabs>
          <Box flexGrow={1} />
        </Toolbar>
        <UserMenu />
      </div>
      {currentTab !== 'dashboard' && (
        <div className="page-title">{currentTab}</div>
      )}
    </>
  )
}
