export const BusinessSVG = ({size = 24}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#E8F2FA" />
    <path
      d="M15.5286 5.52858C15.3333 5.72384 15.3333 6.03811 15.3333 6.66665V15.3333C15.3333 15.9619 15.3333 16.2761 15.5286 16.4714C15.7239 16.6666 16.0381 16.6666 16.6667 16.6666C17.2952 16.6666 17.6095 16.6666 17.8047 16.4714C18 16.2761 18 15.9619 18 15.3333V6.66665C18 6.03811 18 5.72384 17.8047 5.52858C17.6095 5.33331 17.2952 5.33331 16.6667 5.33331C16.0381 5.33331 15.7239 5.33331 15.5286 5.52858Z"
      fill="#1782CA"
    />
    <path
      d="M10.6667 8.66665C10.6667 8.03811 10.6667 7.72384 10.8619 7.52857C11.0572 7.33331 11.3715 7.33331 12 7.33331C12.6285 7.33331 12.9428 7.33331 13.1381 7.52857C13.3333 7.72384 13.3333 8.03811 13.3333 8.66665V15.3333C13.3333 15.9619 13.3333 16.2761 13.1381 16.4714C12.9428 16.6666 12.6285 16.6666 12 16.6666C11.3715 16.6666 11.0572 16.6666 10.8619 16.4714C10.6667 16.2761 10.6667 15.9619 10.6667 15.3333V8.66665Z"
      fill="#1782CA"
    />
    <path
      d="M6.19526 10.1952C6 10.3905 6 10.7048 6 11.3333V15.3333C6 15.9619 6 16.2761 6.19526 16.4714C6.39052 16.6666 6.70479 16.6666 7.33333 16.6666C7.96187 16.6666 8.27614 16.6666 8.4714 16.4714C8.66667 16.2761 8.66667 15.9619 8.66667 15.3333V11.3333C8.66667 10.7048 8.66667 10.3905 8.4714 10.1952C8.27614 9.99998 7.96187 9.99998 7.33333 9.99998C6.70479 9.99998 6.39052 9.99998 6.19526 10.1952Z"
      fill="#1782CA"
    />
    <path
      d="M6 18.1666C5.72386 18.1666 5.5 18.3905 5.5 18.6666C5.5 18.9428 5.72386 19.1666 6 19.1666H18C18.2761 19.1666 18.5 18.9428 18.5 18.6666C18.5 18.3905 18.2761 18.1666 18 18.1666H6Z"
      fill="#1782CA"
    />
  </svg>
)
