// SidePanel.js

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {FieldWithValue} from '../../../components'

export const KYCTab = ({record}: any) => {
  return (
    record?.cards && (
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Provider</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Required</TableCell>
                <TableCell align="left">Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.kyc.map((card: any) => (
                <KYCRow key={card.id} row={card} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  )
}

const StatusField = ({fieldName, fieldValue}: any) => {
  let reasons = fieldValue.split('; ')
  let i = 0
  let reasonStatuses: any[] = [] // Initialize as an empty array
  console.log(reasons.length)

  while (i < reasons.length) {
    // Use '<' instead of '<=' to prevent going out of bounds
    let reasonParts = reasons[i].split(': ')
    let reasonObject = {name: reasonParts[0], value: reasonParts[1]}
    reasonStatuses.push(reasonObject) // Add the new object to the array
    console.log(i, reasonObject)
    i = i + 1
  }

  console.log(reasonStatuses)
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        textAlign: 'left',
      }}>
      <div style={{textTransform: 'capitalize'}}>
        <h3>{fieldName}</h3>
      </div>
      {reasonStatuses.map((reason: any) => (
        <FieldWithValue fieldName={reason.name} fieldValue={reason.value} />
      ))}
      <br />
    </div>
  )
}

const KYCRow = (props: any) => {
  const {row} = props
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          '&:hover': {
            backgroundColor: '#E8E8E8',
            cursor: 'pointer', // Change this to the darker color you desire
          },
        }}
        onClick={() => setOpen(!open)}>
        <TableCell align="left">{row.provider}</TableCell>
        <TableCell align="left">{row.kycInfo.type}</TableCell>
        <TableCell align="left">{row.kycInfo.status}</TableCell>
        <TableCell align="left">
          {row.kycInfo.required ? 'Required' : 'Not Required'}
        </TableCell>
        <TableCell align="left">{row.kycInfo.updateDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h5" gutterBottom component="div">
                  Details
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  {row.kycInfo.statusReason && (
                    <div style={{margin: '10px'}}>
                      <StatusField
                        fieldName="Status Reason"
                        fieldValue={row.kycInfo.statusReason}
                      />
                    </div>
                  )}
                  <div style={{margin: '10px'}}>
                    <h3>Other info</h3>
                    <FieldWithValue
                      fieldName="Application Id"
                      fieldValue={row.kycInfo.kycApplicationId}
                    />
                    <FieldWithValue
                      fieldName="Creation Date"
                      fieldValue={row.updatedAt}
                    />
                    <FieldWithValue
                      fieldName="Creation Date"
                      fieldValue={row.createdAt}
                    />
                  </div>
                </div>
              </Box>
              <Box style={{display: 'flex', flexDirection: 'column'}}></Box>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

// "id": 1,
//  "provider": "getid",
//  "kycInfo": {
//  "type": "FULL_KYC",
//    "status": "DECLINED",
//    "required": true,
//    "updateDate": "2023-08-10T17:38:07.100481",
//    "statusReason": "PROFILE_CHECK: NEEDS_REVIEW; FACE_MATCHING: DECLINED; DOC_CHECK: NEEDS_REVIEW",
//    "kycApplicationId": "21864b8a-22ce-42a2-937c-05487523c31d"
// },
// "createdAt": "2023-08-10T17:38:13.871Z",
//  "updatedAt": "2023-08-10T17:38:13.871Z"
