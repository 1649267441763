import {Button, InputAdornment, TextField} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {useState} from 'react'
import {useLogin, useNotify} from 'react-admin'

import {Logo} from '../../components'
import {AccountSVG} from './icons/account'
import {PasswordSVG} from './icons/password'
import './login.css'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()

  const handleSubmit = (e: any) => {
    e.preventDefault()

    login({username: email, password}).catch(() =>
      notify('Invalid email or password', {type: 'error'}),
    )
  }

  return (
    <div className="container">
      <Grid container spacing={0}>
        <Grid lg={4}>
          <nav className="login-nav">
            <Logo height={55} />
          </nav>
          <div className="form-container">
            <div className="form-title">Sign in</div>
            <div className="form-subtitle">
              Enter your login to get access to admin panel
            </div>

            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div>
                <div className="form-label">Username</div>
                <TextField
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  variant="outlined"
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    marginTop: '8px',
                  }}
                  required
                  fullWidth
                  placeholder="Enter username"
                  id="username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <div className="form-label">Password</div>
                <TextField
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    marginTop: '8px',
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Enter password"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{margin: '24px 0px 16px', height: '40px'}}>
                Sign in
              </Button>
            </form>
          </div>
        </Grid>
        <Grid lg={8}>
          <div className="login-bg">
            <img src={require('./login-bg.png')} className="login-bg-img" />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
