import UserIcon from '@mui/icons-material/Person2Outlined' //Look for new icon

import {AccountCreate} from './AccountCreate'
import {AccountsList} from './AccountsList'

export default {
  list: AccountsList,
  show: AccountsList,
  icon: UserIcon,
  create: AccountCreate,
}
