import {useEffect, useState} from 'react';
import {
  PasswordInput,
  SaveButton,
  SaveContextProvider,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useRedirect,
} from 'react-admin';

const ProfileEditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const ProfileEdit = () => {
  const [admin, setAdmin] = useState();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  useEffect(() => {
    async function fetchAdminData() {
      const myHeaders = new Headers();
      myHeaders.append(
        'Authorization',
        localStorage.getItem('Authorization') ?? '',
      );

      try {
        const response = await fetch(`api/admins/profile`, {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        }).then(response => response.json());
        setAdmin(response);
      } catch (error) {
        console.error('error', error);
      }
    }
    fetchAdminData();
  }, []);

  const profileSave = (profile: any) => {
    //TODO: check this line code and fix ts-ignore
    //@ts-ignore
    dataProvider.update('admins', {id: profile.id, data: profile, admin: {}});
    redirect('list');
  };

  const passwordChange = async (passwords: any) => {
    const myHeaders = new Headers();

    myHeaders.append(
      'Authorization',
      localStorage.getItem('Authorization') ?? '',
    );
    myHeaders.append('Content-Type', 'application/json');

    try {
      const response = await fetch(`api/admins/newpassword`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(passwords),
        redirect: 'follow',
      }).then(response => response.json());

      console.log(response);
      return true;
    } catch (error: any) {
      console.error('error', error);
      throw new Error(`Request failed with status ${error.status}`);
    }
  };

  return (
    <SaveContextProvider value={{}}>
      <SimpleForm
        onSubmit={profileSave}
        record={admin ?? {}}
        toolbar={<ProfileEditToolbar />}>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput type="email" source="email" />
      </SimpleForm>
      <SimpleForm onSubmit={passwordChange} toolbar={<ProfileEditToolbar />}>
        <PasswordInput source="old_password" />
        <PasswordInput source="new_password" />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default ProfileEdit;
