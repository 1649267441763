import {Cell, Pie, PieChart, Tooltip} from 'recharts'

const COLORS = [
  '#1782CA',
  '#5CA7DA',
  '#B9D9EF',
  '#B9D9EF',
  '#B9D9EF',
  '#E8F2FA',
]

export const TransactionsChart = ({transactions}: any) => {
  const size = 200
  const margin = 10
  const outerRadius = size / 2 - margin
  const innerRadius = outerRadius - 36

  const data = (transactions?.details || []).map((tr: any) => ({
    name: tr.paymentType.replace(/_/g, ' '),
    value: tr.count || 0,
  }))

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <PieChart width={size} height={size}>
        <Pie
          data={data}
          cx={size / 2}
          cy={size / 2}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={-270}
          label={false}
          dataKey="value"
          nameKey="name">
          {data.map((entry: any, index: number) => (
            <Cell
              style={{outline: 'none'}}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          cursor={{fill: 'transparent'}}
          contentStyle={{
            color: 'white',
            borderRadius: 8,
          }}
        />
      </PieChart>
    </div>
  )
}
